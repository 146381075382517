import React, { useState } from "react";
import { axiosInstance, getQueryByParameter } from "../const/axiosInstance";
import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants";
import AlertComponent from "../utils/Alert";
import { useStore } from "../store";
import { NotificationContainer } from "react-notifications";
import { popUp } from "../utils/helpers";

const MeetingGuest = () => {
    const [username, setUsername] = useState("");
    const [checked, setChecked] = useState(false);
    const { userStore } = useStore();

    const secret = getQueryByParameter("secret");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const roomId = getQueryByParameter("room_id");
        let singupResult = null;
        if (!checked) {
            const data = { username, secret, roomId };
            singupResult = await userStore.guestUserSignup({
                username,
                secret,
                roomId,
            });
            if (!singupResult.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, singupResult.message);
                return;
            }
        } else {
            singupResult = await axiosInstance.post("/auth/blockedCheck", {
                username,
                secret,
                roomId,
            });
            if (!singupResult.data.status) {
                AlertComponent(
                    ALERT_MESSAGE_TYPE_DANGER,
                    singupResult.data.message
                );
                return;
            }
        }

        const singinResult = await userStore.login({
            username,
            password: singupResult.data,
        });
        if (!singinResult.status) {
            AlertComponent(
                ALERT_MESSAGE_TYPE_DANGER,
                "Օգտանուն գրանցված չէ համակարգում"
            );
            return;
        }
        userStore.setUsername(username);

        if (!roomId) {
            AlertComponent(
                ALERT_MESSAGE_TYPE_DANGER,
                "ՎիդեոԿոնֆերենսը չի գտնվել"
            );
        } else {
            popUp(`/video-room/${roomId}?purpose=Group`);
        }
    };

    return (
        <div className=" w-full App-header relative min-h-screen flex flex-col sm:justify-center items-center">
            <div className="relative w-2/4">
                <div className="relative w-full rounded-3xl flex justify-around items-center  p-6  bg-gray-100 shadow-md">
                    <div className="w-2/4">
                        <div>
                            <img
                                src={require("../assets/images/logo.png")}
                                alt=""
                            />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="w-2/3">
                        <label className="block m-3 text-sm text-gray-700 text-center font-semibold">
                            ՄԻԱՆԱԼ ՏԵՍԱԿՈՆՖԵՐԵՆՍ ԿԱՊԻՆ
                        </label>
                        <div>
                            <input
                                type="text"
                                name="username"
                                maxLength={30}
                                placeholder="Օգտանուն"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                            />
                        </div>

                        <div className="mt-3 w-full flex justify-center items-center">
                            <div className="w-full">
                                <button
                                    type={"submit"}
                                    className="bg-blue-500  p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                >
                                    Միանալ հանդիպմանը
                                </button>
                            </div>
                        </div>
                        <NotificationContainer />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MeetingGuest;
