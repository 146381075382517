import React, { useEffect, useState } from "react";
import {
    ALERT_MESSAGE_TYPE_DANGER,
    ALERT_MESSAGE_TYPE_SUCCESS,
} from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import FileUpload from "../FileUpload";
import Loading from "../Loading";
import { useStore } from "../../store";
import DeleteMessageRoom from "../DeleteMessageRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const UserForm = () => {
    const [data, setData] = useState({
        name: "",
        last_name: "",
        father_name: "",
        tel_number: "",
        birth_place: "",
        work_place: "",
        staff: "",
        email: "",
    });
    const [loading, seTLoading] = useState(true);
    const [loadingImg, setLoadingImg] = useState(false);
    const { userStore } = useStore();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        userStore.getCurrentUser().then((res) => {
            setData({ ...data, ...userStore.userData });
            seTLoading(false);
        });
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        let fields = [
            "name",
            "last_name",
            "father_name",
            "tel_number",
            "email",
        ];
        if (fields.includes(name)) value = value.trim();
        setData({ ...data, [name]: value });
        setDisabled(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await userStore.changePersonalData(data);
        await userStore.getCurrentUser();
        const status = result.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        AlertComponent(status, result.message);
        setDisabled(true);
    };

    const deleteAvatar = async () => {
        const result = await userStore.deleteProfilePhoto();
        const status = result.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        userStore.getCurrentUser();
        AlertComponent(status, result.message);
    };

    const setImagePath = (path) => {
        userStore.setUserImage(path);
        userStore.getCurrentUser();
    };

    const {
        name,
        last_name,
        birth_place,
        father_name,
        tel_number,
        work_place,
        staff,
        email,
    } = data;

    return (
        <>
            {" "}
            {loading ? (
                <Loading />
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="flex justify-start flex-col p-3 "
                >
                    <div className="grid md:grid-cols-3 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={20}
                                name="name"
                                id="name"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={name}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="name"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Անուն
                            </label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={20}
                                name="last_name"
                                id="lastname"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={last_name}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="lastname"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Ազգանուն
                            </label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={20}
                                name="father_name"
                                id="father_name"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={father_name}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="father_name"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Հայրանունը
                            </label>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={20}
                                name="birth_place"
                                id="birth_place"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={birth_place}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="birth_place"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Բնակավայր
                            </label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={30}
                                name="work_place"
                                id="workplace"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={work_place}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="workplace"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Աշխատավայր
                            </label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={20}
                                name="tel_number"
                                id="tel_number"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={tel_number}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="tel_number"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Հեռախոս
                            </label>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="text"
                                maxLength={30}
                                name="staff"
                                id="staff"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={staff}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="staff"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Պաշտոնը
                            </label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input
                                type="email"
                                maxLength={30}
                                name="email"
                                id="email"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={email}
                                onChange={handleChange}
                            />
                            <label
                                htmlFor="email"
                                className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Էլ․ փոստ
                            </label>
                        </div>
                    </div>

                    <button
                        disabled={disabled}
                        type="submit"
                        className={`${
                            disabled ? "bg-gray-600" : "bg-blue-600"
                        } text-white rounded`}
                    >
                        Հաստատել
                    </button>
                    <div className="flex justify-center items-center">
                        <label>Տեղադրել նկար</label>
                        <FileUpload
                            setField={setImagePath}
                            uploadFile={userStore.uploadProfilePhoto}
                            setLoading={setLoadingImg}
                            idName="file-input-profile"
                        />
                        <label>Ջնջել նկարը</label>
                        <button
                            type="button"
                            disabled={!userStore.imagePath}
                            className="inline-flex items-center justify-center h-10 w-10 transition duration-500 ease-in-out text-gray-500  focus:outline-none"
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-red-400"
                                onClick={deleteAvatar}
                            />
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default UserForm;
