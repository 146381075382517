import React from "react";
import "./clock.css";

export default function ModalClock({ setMinutes, setHour }) {
    let hours = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
    ];
    let minutes = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
    ];
    return (
        <div className="modal-main-div">
            <div
                className="modal-child-div"
                style={{ "border-right": "1px solid #e5e7eb" }}
            >
                {hours.map((el, ind) => (
                    <div
                        key={ind}
                        className="each-el-clock"
                        onClick={(e) => {
                            e.stopPropagation();
                            setHour(el);
                        }}
                    >
                        {el}
                    </div>
                ))}
            </div>
            <div className="modal-child-div">
                {minutes.map((el, ind) => (
                    <div
                        key={ind}
                        className="each-el-clock"
                        onClick={(e) => {
                            e.stopPropagation();
                            setMinutes(el);
                        }}
                    >
                        {el}
                    </div>
                ))}
            </div>
        </div>
    );
}
