import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../const/axiosInstance";

const InviteButton = () => {
    const [secret, setSecret] = useState("");

    useEffect(() => {
        const fetch = async () => {
            const url = window.location.href.split("/video-room/");
            const roomId = url[1].split("?")[0];
            const data = await axiosInstance.get(
                `/devices/meetings/${roomId}/secret`
            );
            setSecret(data.data.message);
        };
        fetch();
    }, []);

    const openPrompt = () => {
        const url = window.location.href.split("/video-room/");
        const roomId = url[1].split("?")[0];
        prompt(
            "Copy this link and send it to people you want to meet with",
            `${url[0]}/video-guest?secret=${secret}&room_id=${roomId}`
        );
    };

    return (
        <div id="inviteButton" className="options__button" onClick={openPrompt}>
            <FontAwesomeIcon icon={faUserPlus} />
        </div>
    );
};

export default InviteButton;
