import { useEffect } from "react";

export const AnswerCall = ({ closeModal, name, answerCall }) => {
    useEffect(() => {
        if (name) {
            const audio = document.getElementById("audioAnswer");
            audio.muted = false;
            audio.play();
        }
    }, [name]);

    return (
        <>
            <div className="caller flex flex-col p-3">
                <div className="p-2 mb-3 text-center">
                    <h1>{name} զանգահարում է ձեզ...</h1>
                </div>

                <audio
                    controls
                    muted
                    style={{ display: "none" }}
                    id="audioAnswer"
                >
                    <source src="/callme.mp3" type="audio/mp3" />
                </audio>

                <div className="flex justify-around">
                    <button
                        className="bg-blue-600 text-white hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3"
                        onClick={answerCall}
                    >
                        Պատասխանել
                    </button>
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={closeModal}
                    >
                        հրաժարվել
                    </button>
                </div>
            </div>
        </>
    );
};
