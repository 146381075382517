import React, {useRef, useState} from 'react';
import DateTimePicker from "react-datetime-picker";
import {useStore} from "../../../store";
import DeleteContent from "./DeleteContent";

const FilterUserLogs = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const {adminStore} = useStore()

    const filterUserLogs = (e) => {
        e.preventDefault()
        adminStore.LogsByUser(startDate, endDate)
    }


    return (
        <form onSubmit={filterUserLogs} className="pt-3">
            <div >
                <DateTimePicker onChange={setStartDate} value={startDate} locale="hy-AM" />
            </div>
                <h3 className="py-1">Մինչև</h3>
            <div>
                <DateTimePicker onChange={setEndDate} value={endDate} locale="hy-AM" />

            </div>
            <DeleteContent status="user_logs" startdate={startDate} enddate={endDate}/>

            <button type="submit" className="bg-green-300 h-5 w-36 rounded-lg m-2  text-sm cursor-pointer ">Որոնում</button>
        </form>
    );
};

export default FilterUserLogs;