import React from 'react';
import MessageList from "./MessageList";
import LoggingList from "./LoggingList";
import {observer} from "mobx-react-lite";

const UserLogs = observer(() => {
    return (
        <div className="flex w-full home">
            <LoggingList/>
            <MessageList/>
        </div>
    );
});

export default UserLogs;