import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";

const CameraButton = ({ peerInstance }) => {
    const [mute, setMute] = useState(true);

    const disabledCamera = () => {
        peerInstance.toggleCamera(mute);
        setMute(!mute);
    };

    return (
        <div
            id="stopVideo"
            className={`options__button ${!mute && "background__red"}`}
            onClick={disabledCamera}
        >
            {mute ? (
                <FontAwesomeIcon icon={faVideo} />
            ) : (
                <FontAwesomeIcon icon={faVideoSlash} />
            )}
        </div>
    );
};

export default CameraButton;
