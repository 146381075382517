import React,{createContext} from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import rootStore from './states/index'

const storeContext = createContext()

export const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => rootStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
}