import React, {useContext, useEffect, useState} from "react";
import avatarImage from "../../assets/images/user_avatar.png";
import DeleteMessageRoom from "../../components/DeleteMessageRoom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {ALERT_MESSAGE_TYPE_SUCCESS, BACKEND_URL, MESSAGE_MY} from "../../const/Constants";
import MessageItem from "../../components/MessageItem";
import SOCKET from "../../const/socketNames";
import {useStore} from "../../store";
import {SocketContext} from "../../const/socket";
import {getQueryByParameter} from "../../const/axiosInstance";
import {observer} from "mobx-react-lite";
import FileUpload from "../../components/FileUpload";
import UserChat from "../../states/UserChat";
import AlertComponent from "../../utils/Alert";
import {Menu} from "@headlessui/react";
import User from "../../states/User";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Chat = observer(() => {
        const [offset, setOffset] = useState(0)
        const [message, setMessage] = useState('')
        const [loading, setLoading] = useState(false)
        const [bigFile, setBigFile] = useState(false)
        const {userStore, chatStore, usersStore} = useStore();
        const socket = useContext(SocketContext)
        const [size, setSize] = useState(0)
        
        
        function scrollDown() {
            document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight
        }


        useEffect(() => {
            usersStore.getUsers();
            scrollDown()
        },    
     [])

        

        const deleteChatRoom = async () => {
            if (window.confirm('Ջնջել Նամակները')) {
                const result = await UserChat.deleteMessageRoomById()
                socket.emit(SOCKET.DELETE_ALL_MESSAGES,  chatStore.user)
                if (result.status) {
                    window.location.reload()
                    AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message)
                }
            }
        }


   
        async function moreData() {
            if (chatStore.username !== "ALL") {
                await chatStore.getMessages({ roomId: chatStore.getRoomId, offset: offset + 10 });
                setOffset(offset => offset + 10)
            } else if (chatStore.user === 'ALL') {
                setOffset(offset => offset + 10)
                await chatStore.getAllMessages(offset, 10)
    
            }
        }

        useEffect(function () {
            chatStore.setRoomId(getQueryByParameter('roomId'))
            chatStore.setUsername(getQueryByParameter('username'))
            if (chatStore.getRoomId) {
                socket.emit(SOCKET.JOIN_CONNECTED, userStore.username, true)
                socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, {roomId: chatStore.getRoomId})
            }
            if (chatStore.username === 'Admin')
                chatStore.getAdminMessages()
            else
                chatStore.getMessages({roomId:chatStore.roomId})

            setTimeout(function () {
                scrollDown()
            }, 200)
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM)
            }
        }, [])



        const sendMessage = () => {
            if (!message) {
                return
            }
            // chatStore.addMessage(message, MESSAGE_MY,
            //     new Date().toLocaleDateString('en',
            //         {day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'}))
            socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
                message: message.trim(),
                room_id: chatStore.getRoomId,
                role: userStore.role,
                sender_username: userStore.username,
                receiver_username: chatStore.username,
                size
            })
            setMessage('')

            setTimeout(function () {
                scrollDown()
            }, 0)
        }

        useEffect(() => {
            socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
                chatStore.addMessage(message.message, MESSAGE_MY, new Date().toLocaleDateString('en', {
                    day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
                }), null, message._id)
                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
                chatStore.setIsRead()
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            })
            return () => {
                socket.off(SOCKET.CHAT_REFRESH_ROOMS)
            }
        }, [])

        useEffect(() => {
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN, function (data) {
                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})


                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {

                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})

                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.UPDATE_MESSAGES, () => {
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            })
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN)
                socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE)
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE)
            }
        }, [socket])

    const deleteChatMessage = async (message) => {
        if (window.confirm('Ջնջել Նամակը')) {
            chatStore.deleteMessageById(message).then(res => { 
                socket.emit(SOCKET.DELETE_MESSAGE,  chatStore.user)
                chatStore.getMessages({roomId: chatStore.getRoomId})
            })
        }
    }

  


        return (
            <div className="App flex flex-col h-full bg-white  sm:p-3 justify-between z-40">
                <div className="flex sm:items-center pb-2 justify-between  border-b-2 border-gray-200">
                    <div className="relative flex items-center space-x-4">
                        <div className="relative">
                        {(chatStore.user !== 'ALL') && <img
                             src={usersStore.users.find(el => el.username === chatStore.user)?.image ? `${BACKEND_URL}${usersStore.users.find(el => el.username === chatStore.user).image}` : avatarImage}
                             alt="avatar" className="w-10 sm:w-16 h-10 sm:h-16 rounded-full"/>}
                        </div>
                        <div className="flex flex-col leading-tight">
                            <div className="text-2xl mt-1 flex items-center">
                                <span className="text-gray-700 mr-3">{(usersStore.users.find(el => el.username === chatStore.user)?.name || usersStore.users.find(el => el.username === chatStore.user)?.last_name || usersStore.users.find(el => el.username === chatStore.user)?.father_name) ? usersStore.users.find(el => el.username === chatStore.user)?.name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.last_name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.father_name : chatStore.user === 'ALL' ? 'Իմ Նամակները' : chatStore.user}</span>
                            </div>
                            <span className="text-lg text-gray-600"/>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className="flex items-center space-x-2 mr-3">
                            <button type="button"
                                    onClick={() => deleteChatRoom()}
                                    className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-red-200 focus:outline-none">
                                <DeleteMessageRoom/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 h-4/5 flex-col space-y-4 p-3 ">
                    <div id='messages'
                         className='h-full overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch'>
                        {chatStore.messages.length >= 10 && chatStore.messages.length > offset ? <button onClick={() => moreData()}>Տեսնել ավելին</button> : ""}
                        {chatStore.messages.filter(el => el).map((message, index) => {
                            if (message.type === MESSAGE_MY) {
                                return (<div className="chat-message" key={index}>
                                    <div className="flex items-end justify-end">
                                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                                            {(chatStore.user === 'ALL') && (
                                                <div className="hover:cursor-pointer">
                                                    {message.receiver_username}
                                                </div>)}
                                            <div className="flex flex-col">
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div className='flex  py-2 space-x-5 text-center'>
                                                        <Menu.Button>
                                                            <span
                                                                className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white text-start min-w-15 max-w-md break-words">
                                                                <MessageItem message={message.message}/>
                                                            </span>
                                                        </Menu.Button>
                                                    </div>
                                                    <Menu.Items
                                                        className="absolute z-10 w-40 rounded-md shadow-lg bg-white  focus:outline-none">
                                                        <div className="py-1">
                                                            <Menu.Item>
                                                                {({active}) => (<button onClick={() => deleteChatMessage(message)}
                                                                                        className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Ջնջել</button>)}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Menu>
                                            </div>
                                            <span
                                                className=" text-xxs text-gray-500 text-end px-2">{message.created_at} {message.is_viewed ? (
                                                    <FontAwesomeIcon icon={faCheckCircle}
                                                                     className="fa fa-check-circle text-green-400"
                                                                     aria-hidden="true"></FontAwesomeIcon>)
                                                : (<FontAwesomeIcon icon={faCheck} className="fa fa-check"
                                                                    aria-hidden="true"></FontAwesomeIcon>)}</span>
                                        </div>
                                    </div>
                                </div>)
                            } else {
                                return (<div className="chat-message" key={index}>
                                    <div className="flex items-end">
                                        <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                            {(chatStore.user === 'ALL') && (
                                                <div className="hover:cursor-pointer">
                                                    {message.username}
                                                </div>)}
                                            <div className="flex flex-col">
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div className='flex  py-2 space-x-5 text-center'>
                                                        <Menu.Button>
                                                <span
                                                    className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600 min-w-15 text-start max-w-md break-words">
                                                        <MessageItem message={message.message}/>
                                                </span>
                                                        </Menu.Button>
                                                    </div>
                                                    <Menu.Items
                                                        className="absolute z-10 w-40 rounded-md shadow-lg bg-white  focus:outline-none">
                                                        <div className="py-1">
                                                            <Menu.Item>
                                                                {({active}) => (<button onClick={() => deleteChatMessage(message)}
                                                                                        className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>Ջնջել</button>)}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Menu>

                                                <span
                                                    className="block text-xxs text-gray-500 text-end p-2">{message.created_at} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                </div>
                <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                    <div className="relative flex">
                        <div className="basis-8/12">
                            <div className="absolute left-2 top-1">
                                <FileUpload setField={setMessage} setSize={setSize} setLoading={setLoading} uploadFile={chatStore.uploadMessages} setBigFile={setBigFile} />
                            </div>
                            <textarea
                                // type="text"
                                rows={Math.ceil(message.length / 25) < 4 ? Math.ceil(message.length / 25) : 4} cols="100"
                                value={message}
                                onKeyDown={(event) => event.key === 'Enter' && (event.ctrlKey || event.metaKey || event.shiftKey) ? (() =>{event.preventDefault(); sendMessage()})() : ''}
                                onChange={(event) => message.length < 4000 ? setMessage(event.target.value) : ""}
                                placeholder={bigFile? "Ֆայլը չպետք է գերազանցի 5GB-ը" : loading ? "Բեռնվում է..." : "Հաղորդագրություն"}
                                className="w-full resize-none focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"/>
                        </div>
                        <div
                            className="absolute basis-auto right-0 items-center inset-y-0 hidden sm:flex">
                            <button
                                type="button"
                                onClick={sendMessage}
                                className="inline-flex items-center justify-center rounded-lg px-4 py-3 bg-blue-600 text-white hover:bg-blue-400 focus:outline-none">
                                <span className="font-bold mr-1">Ուղարկել</span>
                                <FontAwesomeIcon icon={faPaperPlane}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)
export default Chat;