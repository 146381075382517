import React, { useEffect, useState, memo } from "react";
import { BACKEND_URL } from "../const/Constants";

const MessageItem = ({ message }) => {
    const [isFile, setIsFile] = useState(false);

    useEffect(() => {
        if (message && message.includes("uploads")) {
            setIsFile(true);
        } else {
            setIsFile(false);
        }
    }, [message]);

    return (
        <p className="whitespace-pre-wrap">
            {isFile ? (
                <a href={`${BACKEND_URL}${message}`} download target="_blank">
                    <img
                        src={`${BACKEND_URL}${message}`}
                        alt={`${message.split("uploads")[1]}`}
                    />
                </a>
            ) : message?.includes("http") ? (
                <a href={message} target="_blank">
                    {message}
                </a>
            ) : (
                message
            )}
        </p>
    );
};

export default memo(MessageItem);
