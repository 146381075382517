export const MESSAGE_MY = "MY";
export const MESSAGE_OTHER = "OTHER";
export const ADMIN_ROLE = "Admin"
export const ALERT_MESSAGE_TYPE_DANGER = "error"
export const ALERT_MESSAGE_TYPE_SUCCESS = "success"
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const PEER_HOSTNAME= process.env.REACT_APP_PEER_HOSTNAME
export const PEER_URL="/peerjs"
export const PEER_PORT=process.env.NODE_ENV === "production" ? "443" : "9000"
export const PEER_SECURE=process.env.REACT_APP_ENV === "production"


// Constants for refresh
export const MEETING_LIST = 'MEETING_LIST'
