import React from 'react';
import ChangeMeetingCreatePermission from "./ChangeMeetingCreatePermission";
import DeletePassword from "./DeletePassword";
import Users from "../../../states/Admin/Users";
import {observer} from "mobx-react-lite";


const Edit = observer(() => {
    const users = Users
    return (
        <div className="flex justify-around w-hull home flex-wrap " >
            <div className='w-full bg-white border-b p-2'>
                {users.active_username}
            </div>
            <DeletePassword/>
            <ChangeMeetingCreatePermission/>
        </div>
    );
});

export default Edit;