import React, {useRef, useState} from 'react';
import {useStore} from "../../../store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const DeletePassword = () => {
     const {adminStore} = useStore()
    const [value,setValue] = useState('');
    const [viewpassword, setViewPassword] = useState("password")

    const resetUserPassword = () => {
        adminStore.deleteUserPassword({password: value})
        setValue('')
     }
    const handleViewPassword = () =>{
        if(viewpassword === "password" ){
            setViewPassword("text")
        }else{
            setViewPassword("password")
        }

    }


    return (
        <div className='bg-white basis-1/2 p-2'>
           <h2> Փոխել օգտվողի գաղտնաբառը </h2>
            <form className="relative z-0 w-full group" autoComplete="chrome-off">
                <input type={viewpassword} name="password" id="password" autoComplete="chrome-off"
                       className="block w-full py-2.5 px-0 pr-10 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={value} onChange={(e) => setValue(e.target.value)} />
                <label htmlFor="password"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Գաղտնաբառ</label>
                <div className="absolute top-0 right-0">
                    {viewpassword==="password" ? <FontAwesomeIcon icon={faEyeSlash} className="fa fa-eye-slash  m-3 cursor-pointer" aria-hidden="true" onClick={handleViewPassword}></FontAwesomeIcon>
                        : <FontAwesomeIcon className="fas fa-eye m-3 cursor-pointer" icon={faEye} onClick={handleViewPassword}></FontAwesomeIcon>}
                </div>
                <button onClick={()=> resetUserPassword()} className="my-2 border p-3 rounded hover:bg-green-200">Փոխել</button>

            </form>

        </div>
    );
};

export default DeletePassword;