import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { NotificationContainer } from "react-notifications";
import { useStore } from "../store";
import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants";
import {
    passwordValidation,
    usernameSymbolValidation,
    usernameValidation,
} from "../utils/validate";
import AlertComponent from "../utils/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = observer(() => {
    const { userStore } = useStore();
    const [data, setData] = useState({
        username: "",
        password: "",
        confirm_password: "",
        pin_code: "",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [viewpassword, setViewPassword] = useState("password");
    const [disabled, setDisabled] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const { username, password, pin_code, confirm_password } = data;
        const allowed = /[A-z0-9\._\-]/gm;
        if ((username.length && username.length < 3) || username.length > 18) {
            setErrorMessage(
                "Օգտանունը պետք է լինի մինիմում 3 սիմվոլ և մաքսիմում 18 սիմվոլ"
            );
            setDisabled(true);
        } else if (username && username.match(allowed).join("") !== username) {
            setErrorMessage(
                'Օգտանունը կարող է պարունակել լատինատառ սիմվոլներ, թվեր և " ․ " _ " - "'
            );
            setDisabled(true);
        } else if (password && password.length < 8) {
            setErrorMessage("Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ");
            setDisabled(true);
        } else if (confirm_password && password !== confirm_password) {
            setErrorMessage("Գաղտնաբառը չի համընկնում");
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });

        if (name === "username") {
            const result = usernameValidation(value);
            const symbolValidation = usernameSymbolValidation(value);
            !result.isValid
                ? setErrorMessage(result.message)
                : setErrorMessage("");
            !symbolValidation.isValid
                ? setErrorMessage(symbolValidation.message)
                : setErrorMessage("");
        } else if (name === "pin_code") {
        } else if (name === "confirm_password") {
            const result = passwordValidation(value);
            !result.isValid
                ? setErrorMessage(result.message)
                : setErrorMessage("");
            data.password !== value &&
                setErrorMessage("Գաղտնաբառը չի համընկնում");
        } else {
            const result = passwordValidation(value);
            !result.isValid
                ? setErrorMessage(result.message)
                : setErrorMessage("");
        }
    };

    async function join() {
        const { username, password, pin_code, confirm_password } = data;
        if (!password && !username && !pin_code && !confirm_password) {
            setErrorMessage("Անհրաժեշտ է լրացնել դաշտերը");
            return;
        }
        if (password !== confirm_password) {
            setErrorMessage("Գաղտնաբառը չի համընկնում");
            return;
        }
        if (errorMessage) {
            return;
        }
        const checkUsernameValid = usernameValidation(username);
        if (!checkUsernameValid.isValid) {
            setErrorMessage(checkUsernameValid.message);
        }
        if (password.length < 8) {
            setErrorMessage("Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ");
        }
        const result = await userStore.register({
            username,
            password,
            pin_code,
        });
        if (!result.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message);
        } else {
            navigate("/");
        }
    }

    const handleViewPassword = () => {
        if (viewpassword === "password") {
            setViewPassword("text");
        } else {
            setViewPassword("password");
        }
    };

    return (
        <div className=" w-full App-header relative min-h-screen flex flex-col sm:justify-center items-center">
            <div className="relative w-2/4">
                <div className="relative w-full rounded-3xl flex justify-around items-center  p-6  bg-gray-100 shadow-md">
                    <div className="w-2/4">
                        <div>
                            <img
                                src={require("../assets/images/logo.png")}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="w-2/3">
                        <label className="block m-3 text-sm text-gray-700 text-center font-semibold">
                            ՏԵՍԱԿՈՆՖԵՐԵՆՍ ԿԱՊ
                        </label>
                        <div>
                            <input
                                type="text"
                                name="username"
                                placeholder="Անուն"
                                value={data.username}
                                onChange={(e) => handleChange(e)}
                                className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                            />
                        </div>
                        <div className="flex justify-center items-center">
                            <input
                                type={`${viewpassword}`}
                                name="password"
                                placeholder="Գաղտնաբառ"
                                value={data.password}
                                onKeyDown={(event) =>
                                    event.key === "Enter" ? join() : ""
                                }
                                onChange={(e) => handleChange(e)}
                                className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                            />
                            {viewpassword === "password" ? (
                                <FontAwesomeIcon
                                    className="fa fa-eye-slash  m-3 cursor-pointer"
                                    icon={faEyeSlash}
                                    aria-hidden="true"
                                    onClick={handleViewPassword}
                                ></FontAwesomeIcon>
                            ) : (
                                <FontAwesomeIcon
                                    className="fas fa-eye m-3 cursor-pointer"
                                    icon={faEye}
                                    onClick={handleViewPassword}
                                ></FontAwesomeIcon>
                            )}
                        </div>

                        <div>
                            <input
                                type="password"
                                name="confirm_password"
                                placeholder="Կրկնել Գաղտնաբառը"
                                value={data.confirm_password}
                                onChange={(e) => handleChange(e)}
                                className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                            />
                        </div>

                        <div>
                            <input
                                type="password"
                                name="pin_code"
                                placeholder="Լրացնել PIN կոդը"
                                value={data.pin_code}
                                onKeyDown={(event) =>
                                    event.key === "Enter"
                                        ? disabled
                                            ? ""
                                            : join()
                                        : ""
                                }
                                onChange={(e) => handleChange(e)}
                                className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                            />
                        </div>
                        {errorMessage === "" ? null : (
                            <span className="text-red-500 text-sm text-center">
                                {errorMessage}
                            </span>
                        )}
                        <div className="mt-7 w-full flex justify-center items-center">
                            <div className="w-full">
                                <button
                                    onClick={() => join()}
                                    disabled={disabled}
                                    className="bg-blue-500  p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                >
                                    Գրանցվել
                                </button>
                                <a
                                    href="/support.pdf"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="ml-2 text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                >
                                    Օգնություն
                                </a>
                            </div>
                            <div className="w-52">
                                <a
                                    href="/"
                                    className=" text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                >
                                    Մուտք գործել
                                </a>
                            </div>
                        </div>
                        <NotificationContainer />
                    </div>
                </div>
            </div>
        </div>
    );
});
export default Login;
