import "./App.css";
import "react-notifications/lib/notifications.css";
import React from "react";
import Login from "./pages/Login";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import { SocketContext, socket } from "./const/socket";
import { AdminAuthenticated, RequireAuth } from "./HOC/RequireAuth";
import Dashboard from "./pages/Admin/Dashboard";
import Room from "./pages/Room";
import MeetingGuest from "./pages/MeetingGuest";
import PrivateChat from "./components/PrivateChat";
import Chat from "./pages/Admin/Chat";

function App() {
  return (
    <div>
      <SocketContext.Provider value={socket}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/video-room/:roomId"
              element={
                <RequireAuth>
                  <Room />
                </RequireAuth>
              }
            />
            <Route
              path="/chat-admin"
              element={
                <RequireAuth>
                  <PrivateChat />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <AdminAuthenticated>
                  <Dashboard />
                </AdminAuthenticated>
              }
            />
            <Route
              path="/chat"
              element={
                <RequireAuth>
                  <Chat />
                </RequireAuth>
              }
            />
            <Route path="/video-guest" element={<MeetingGuest />} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </Router>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
