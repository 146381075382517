import {
  makeObservable,
  runInAction,
  computed,
  observable,
  action,
} from "mobx";
import { axiosInstance } from "../const/axiosInstance";
import AlertComponent from "../utils/Alert";
import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants";
import userChat from "./UserChat";

class UsersList {
  users = [];
  onlineUsers = [];
  unviewedChats = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      onlineUsers: observable,
      unviewedChats: observable,
      usersList: computed,
      setOnlineUsersList: action,
      viewedNewMessages: action,
      getUnreadMessages: action,
      removeUnviewedChats: action,
      setUnviewedChats: action,
      fullName: action,
    });
  }

  get usersList() {
    return this.users;
  }

  fullName(user) {
    if (user.name || user.last_name || user.father_name)
      return user.name + " " + user.last_name + " " + user.father_name;
    return user.username;
  }

  setOnlineUsersList(value) {
    this.onlineUsers = value;
  }

  setUnviewedChats(value) {
    if (value && value !== userChat.username) {
      this.unviewedChats.push(value);
    }
  }

  removeUnviewedChats(username) {
    this.unviewedChats = this.unviewedChats.filter((el) => el !== username);
  }

  async getUsers(search) {
    try {
      const {
        data: { data: response },
      } = await axiosInstance.get(`/auth/users`, {
        params: {
          search: search ? search : "",
        },
      });
      runInAction(() => {
        const username = JSON.parse(localStorage.getItem("username")) ?? "";
        this.users = response.filter((el) => el.username !== username);
        localStorage.setItem("users", JSON.stringify(this.users));
      });
    } catch (error) {
      AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message);
      this.username = null;
      localStorage.removeItem("username");
    }
  }

  async getUnreadMessages() {
    try {
      const {
        data: { data: response },
      } = await axiosInstance.get(`/devices/messages/unread`);
      runInAction(() => {
        this.unviewedChats = response;
      });

      return response;
    } catch (error) {
      AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message);
    }
  }

  async viewedNewMessages() {
    // const unreadUsers = await  this.getUnreadMessages()
    this.users = this.users.filter((el) =>
      this.unviewedChats.includes(el.username)
    );
  }
}

export default new UsersList();
