import { useRef, useState } from "react";
import VideoRoom from "../../states/VideoRoom";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import SelectOption from "../SelectOption";
import { useStore } from "../../store";
import { useEffect } from "react";
import {
    ALERT_MESSAGE_TYPE_DANGER,
    ALERT_MESSAGE_TYPE_SUCCESS,
} from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import { socket } from "../../const/socket";
import SOCKET from "../../const/socketNames";
import { getDateByMonthAndHour } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../const/axiosInstance";
import Clock from "../Clock/Clock";

function ChangeRoom({ room, closeModal }) {
    const [roomName, setRoomName] = useState(room.name);
    const [date, setDate] = useState(new Date(room.start_date));
    const [hour, setHour] = useState(
        new Date(room.start_date).toString().substr(16, 5)
    );
    const [requestedDate, setRequestedDate] = useState("");
    const { usersStore, videoRoomStore } = useStore();
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState([]);
    const [options] = useState(function () {
        var res = [];
        Object.keys(usersStore.users).forEach((index) => {
            res = [
                ...res,
                {
                    value: usersStore.users[index].username,
                    label:
                        usersStore.users[index].name ||
                        usersStore.users[index].last_name
                            ? usersStore.users[index].name +
                              " " +
                              usersStore.users[index].last_name
                            : usersStore.users[index].username,
                },
            ];
        });
        return res;
    });
    const onChange = (selectedOptions) => setSelected(selectedOptions);

    useEffect(() => {
        setSelected(
            room.invited_users.map((el) => ({
                value: el,
                label: videoRoomStore.getFullNameByUserName(el),
            }))
        );
    }, []);

    const countRef = useRef("");

    useEffect(() => {
        const getTime = async () => {
            const date = await axiosInstance.get("/devices/time");
            setRequestedDate(date.data.data);
        };
        getTime();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const invited_users = selected.map((el) => el.value);

        const newIsoDate = new Date(date.toISOString());
        newIsoDate.setHours(newIsoDate.getHours() + 4);
        const isoDate = new Date(
            getDateByMonthAndHour(newIsoDate.toISOString(), hour)
        );
        isoDate.setHours(isoDate.getHours() - 4);
        const result = await VideoRoom.updateVideoRoomById({
            roomId: room._id,
            info: {
                room_name: e.target.room.value,
                count: countRef.current.value,
                start_date: isoDate.toISOString(),
                invited_users,
            },
        });
        const new_invited_users = new Set([
            ...room.invited_users,
            ...invited_users,
        ]);

        socket.emit(SOCKET.MEETING_CREATE, Array.from(new_invited_users));
        const status = result.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        AlertComponent(status, result.message);
        closeModal();

        VideoRoom.getMyVideoMeetings();
    };

    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value);
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "400px" }}>
            <div
                className="flex justify-center flex-col"
                onClick={() => setOpenModal(false)}
            >
                <div className="flex items-center justify-between space-x-2">
                    <h2 className="text-center	">
                        {" "}
                        Փոփոխել Կոնֆերենսի տվյալները
                    </h2>

                    <button
                        type="button"
                        onClick={() => closeModal()}
                        className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faWindowClose} />
                    </button>
                </div>

                <label htmlFor="room">Հանդիպման անունը :</label>
                <input
                    type="text"
                    id="room"
                    name="room"
                    maxLength={30}
                    className="border-2 mb-2"
                    value={roomName}
                    onChange={(e) => handleRoomNameChange(e)}
                    required
                />

                <label htmlFor="room">Հանդիպման մասնակիցների քանակը:</label>
                <input
                    type="number"
                    id="participant_count"
                    name="count"
                    disabled
                    ref={countRef}
                    value={selected.length}
                    className="border-2 mb-2"
                    required
                />
                <div className="py-3 pr-2 ">
                    <SelectOption
                        isMulti
                        value={selected}
                        options={options}
                        onChange={onChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Ընտրել օգտվողներին"
                    />
                </div>

                <Calendar
                    value={date}
                    onChange={setDate}
                    minDate={new Date()}
                />
                <div style={{ display: "flex", "margin-top": "5px" }}>
                    <label htmlFor="appt">Ընտրեք ձեր հանդիպման ժամը։ </label>
                    <Clock
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        date={date}
                        requestedDate={requestedDate}
                        setHour={setHour}
                        setError={setError}
                    />
                </div>
                {error && (
                    <div style={{ color: "red" }}>
                        Ժամը անհրաժեշտ է ընտրել սկսած{" "}
                        {`${
                            new Date(requestedDate).getHours() < 10
                                ? "0" + new Date(requestedDate).getHours()
                                : new Date(requestedDate).getHours()
                        }:${
                            new Date(requestedDate).getMinutes() < 10
                                ? "0" + new Date(requestedDate).getMinutes()
                                : new Date(requestedDate).getMinutes()
                        }֊ից`}
                    </div>
                )}
                <button
                    className="bg-blue-600 text-white h-15 w-15 mt-2"
                    disabled={error}
                    type="submit"
                >
                    Ընդունել փոփոխությունը
                </button>
            </div>
        </form>
    );
}

export default ChangeRoom;
