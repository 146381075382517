import React, { useState } from "react";
import { ALERT_MESSAGE_TYPE_DANGER } from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import "./style.css";

const CheckDevices = () => {
    const [start, setStart] = useState(false);
    const [startVideo, setStartVideo] = useState(false);
    const [audioContext, setAudioContext] = useState(null);

    const checkAudio = () => {
        setStart(true);
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: false,
            })
            .then(function (stream) {
                const allPids = [...document.querySelectorAll(".pid")];
                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(stream);
                const scriptProcessor = audioContext.createScriptProcessor(
                    2048,
                    1,
                    1
                );

                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(scriptProcessor);
                setAudioContext(audioContext);
                scriptProcessor.connect(audioContext.destination);
                scriptProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    const arraySum = array.reduce((a, value) => a + value, 0);
                    const average = arraySum / array.length;
                    colorPids(average, allPids);
                };
            })
            .catch(function (err) {
                AlertComponent(
                    ALERT_MESSAGE_TYPE_DANGER,
                    "Միկրոֆոնը միացված չէ"
                );
            });
    };
    const cancelAudio = () => {
        if (audioContext) {
            setStart(false);
            audioContext.close();
        }
    };

    function colorPids(vol, allPids) {
        const numberOfPidsToColor = Math.round(vol / 10);
        const pidsToColor = allPids.slice(0, numberOfPidsToColor);
        for (const pid of allPids) {
            pid.style.backgroundColor = "#e6e7e8";
        }
        for (const pid of pidsToColor) {
            pid.style.backgroundColor = "#69ce2b";
        }
    }

    const checkCamera = () => {
        //todo: many bug double view camera
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: true,
            })
            .then(function (stream) {
                const videoGrid = document.getElementById("video-grids");
                const videoElement = document.getElementById("video_test");
                if (videoElement) {
                    return;
                }
                const video = document.createElement("video");
                video.setAttribute("id", "video_test");
                video.srcObject = stream;

                video.addEventListener("loadedmetadata", () => {
                    video.play();
                    setStartVideo(true);
                    videoGrid.append(video);
                });
            })
            .catch((e) => {
                AlertComponent(
                    ALERT_MESSAGE_TYPE_DANGER,
                    "Տեսախցիկը միացված չէ"
                );
            });
    };

    const cancelCamera = () => {
        const videoElement = document.getElementById("video_test");
        videoElement
            ?.captureStream()
            .getTracks()
            ?.forEach((track) => track.readyState === "live" && track.stop());
        videoElement && videoElement.remove();
        setStartVideo(false);
    };

    const testAudio = () => {
        const audioElement = document.getElementById("audio");
        audioElement.play();
    };
    const stopAudio = () => {
        const audioElement = document.getElementById("audio");
        audioElement.pause();
    };

    return (
        <div className="flex justify-around">
            <div className="microphone   flex-1">
                <h3>Միկրոֆոնի ստուգում</h3>
                <button
                    onClick={checkAudio}
                    className="bg-blue-600 text-white  w-13 p-3 text-center m-2"
                >
                    Թեստ
                </button>
                <button
                    onClick={cancelAudio}
                    className="bg-red-300 text-white  w-13 p-3 text- m-2"
                >
                    Չեղարկել
                </button>
                <div
                    className={`pids-wrapper w-full ${
                        start ? "flex" : "hidden"
                    }`}
                >
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                    <div className="pid" />
                </div>
            </div>
            <div className="camera flex-1">
                <h3>Տեսախցիկի ստուգում</h3>
                <div id="video-grids"></div>
                <button
                    onClick={checkCamera}
                    className="bg-blue-600 text-white  w-13 p-3 text-center m-2"
                    disabled={startVideo}
                >
                    Թեստ
                </button>
                <button
                    onClick={cancelCamera}
                    className="bg-red-300 text-white  w-13 p-3 text- m-2"
                >
                    Չեղարկել
                </button>
            </div>
            <div className="flex-1">
                <h3>Ձայնի ստուգում</h3>
                <button
                    onClick={testAudio}
                    className="bg-blue-600 text-white  w-13 p-3 text-center m-2"
                >
                    Թեստ
                </button>
                <button
                    onClick={stopAudio}
                    className="bg-red-300 text-white  w-13 p-3 text- m-2"
                >
                    Չեղարկել
                </button>
                <audio id={"audio"} controls={false}>
                    <source src="/callme.mp3" />
                </audio>
            </div>
        </div>
    );
};

export default CheckDevices;
