import { createContext } from "react";
import socketio from "socket.io-client";
import { SOCKET_URL } from "./Constants";

const options =
    process.env.REACT_APP_ENV === "production"
        ? {
              secure: true,
              reconnection: true,
              reconnectionAttempts: 10,
              closeOnBeforeunload: false,
              auth: {
                  username: window.localStorage.getItem("user"),
                  session: window.localStorage.getItem("session"),
              },
          }
        : {
              secure: false,
              reconnection: true,
              rejectUnauthorized: false,
              reconnectionAttempts: 10,
              closeOnBeforeunload: false,
              auth: {
                  username: window.localStorage.getItem("user"),
                  session: window.localStorage.getItem("session"),
              },
          };

export const socket = socketio.connect(SOCKET_URL, options);
export const SocketContext = createContext();
