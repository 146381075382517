import React, {useEffect, useState} from 'react';
import {Menu} from "@headlessui/react";
import {useStore} from "../../../store";
import  {observer} from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../../components/modal/CustomModal';
import { axiosInstance } from '../../../const/axiosInstance';
import { useNavigate } from 'react-router-dom';

const PinCodeList = observer(() => {
    const {adminStore} = useStore()
    const [isOpen, setIsOpen] = useState(false)
    const [pin, setPin] = useState("")
    const [checkedRoom, setCheckedRoom] = useState(null)
    const [getPins, setGetPins] = useState(false)

    useEffect(() => {
        adminStore.getAllPinCodes()
    }, [getPins])

    const closeModal= () => {
        setIsOpen(false)
        setCheckedRoom(null)
        setGetPins(!getPins)
    }

    let chnagePinDate = async () => {
        await axiosInstance.post(`/admin/change_pin`, {...checkedRoom, pin})
        closeModal()
    }

    let deletePinDate = async (pin) => {
        await axiosInstance.post(`/admin/delete_pin`, {pin})
        window.location = "/dashboard"
    }

    return (
        <div className="flex flex-col border-r border-slate-300 overflow-hidden  basis-full">
            <div className='border-b p-3'>
                <span className='font-bold'>Փին կոդերի ցուցակ</span>
            </div>

            <div className='overflow-y-scroll'>
                <table className="border-collapse border border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 ">Անվանում</th>
                        <th className="border border-slate-300 ">Օգտվողների քանակություն</th>
                        <th className="border border-slate-300 ">Օգտվողների սահման</th>
                        <th className="border border-slate-300 ">Ում է տրվել</th>
                    </tr>
                    </thead>
                    {/*<InfiniteScroll */}
                    {/*      dataLength={50}*/}
                    {/*      next={VideoRoom.getAllVideoMeetings()}*/}
                    {/*      hasMore={true}*/}
                    {/*     // loader={}*/}
                    {/*>*/}
                    <tbody className=''>
                    {adminStore.pin_codes.map((room, idx) => (
                        <Menu key={idx} as="tr"
                              className={"relative p-1 border text-gray-500 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer "}>
                            <Menu.Button as={'td'} className='border border-slate-300 '>{room.code}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_count}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_limit}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_username}</Menu.Button>
                            <Menu.Button as={'td'}>
                                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                     <FontAwesomeIcon icon={faTrash} style={{marginRight: "10px"}} onClick={() => deletePinDate(room.code)}/>
                                     <FontAwesomeIcon icon={faPenToSquare} onClick={() => {setCheckedRoom(room); setIsOpen(true); setPin(room.code)}}/>
                                </div>
                            </Menu.Button>

                        </Menu>
                    ))}
                    
                    </tbody>
                    {/*</InfiniteScroll>*/}
                </table>
                <CustomModal isClose={closeModal} isOpen={isOpen}>
                    <div style={{padding: "0 20px"}}>
                          <div onClick={closeModal} style={{display:"grid",justifyContent: "end"}}>
                                <div style={{border: "1px solid gray", width: "fit-content", padding: "2px 10px", borderRadius: "5px" }}>
                                    <FontAwesomeIcon icon={faClose}/>
                                </div>
                          </div>
                          <div>
                                <p >Փին կոդ</p>
                                <input type="text" value={checkedRoom?.code} onChange={(e) => setCheckedRoom({...checkedRoom, code: e.target.value})}
                                className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-3"/>
                          </div>
                          <div>
                                <p>Օգտվողների սահման</p>
                                <input type="number" value={checkedRoom?.used_limit} onChange={(e) => setCheckedRoom({...checkedRoom, used_limit: e.target.value})}
                                className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-3"/>
                          </div>
                          <div>
                                <p>Ում է տրվել</p>
                                <input type="text" value={checkedRoom?.used_username} onChange={(e) => setCheckedRoom({...checkedRoom, used_username: e.target.value})}
                                className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mb-3"/>
                          </div>
                          <button className="bg-blue-600 text-white rounded p-2 m-2 center" onClick={chnagePinDate}>Հաստատել</button>
                    </div>      
                </CustomModal>
            </div>
        </div>
    );
});

export default PinCodeList;