import { useCallback, useRef, useState } from "react";
import { useStore } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SocketContext } from "../../const/socket";
import SelectOption from "../SelectOption";
import FileUpload from "../FileUpload";
import { useContext } from "react";
import UserChat from "../../states/UserChat";
import SOCKET from "../../const/socketNames";
import { MESSAGE_MY } from "../../const/Constants";
import { observer } from "mobx-react-lite";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const NewMessage = observer(({ setIsOpen }) => {
    const { usersStore, userStore } = useStore();
    const [selected, setSelected] = useState([]);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bigFile, setBigFile] = useState(false);
    const [filename, setFileName] = useState("");
    const errorMessage = useRef();

    const [options] = useState(function () {
        var res = [];
        Object.keys(usersStore.users).forEach((index) => {
            res = [
                ...res,
                {
                    value: usersStore.users[index].username,
                    label:
                        usersStore.users[index].name ||
                        usersStore.users[index].last_name
                            ? usersStore.users[index].name +
                              " " +
                              usersStore.users[index].last_name
                            : usersStore.users[index].username,
                },
            ];
        });
        return res;
    });

    const socket = useContext(SocketContext);

    const onChange = useCallback(
        (selectedOptions) => setSelected(selectedOptions),
        []
    );
    const chatCloseHandleClick = () => {
        setIsOpen(false);
    };

    const sendMessage = () => {
        selected.map((el) => {
            UserChat.addMessage(
                message,
                MESSAGE_MY,
                new Date().toLocaleDateString("en", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "numeric",
                    hour12: false,
                    minute: "numeric",
                }),
                el.value
            );
        });
        socket.emit(SOCKET.SEND_MESSAGE_ALL, {
            usernames: selected.map((el) => el.value),
            message,
            sender_username: userStore.username,
            filename,
        });
        chatCloseHandleClick();
    };

    return (
        <div className="h-96">
            <div className="group">
                <div className="flex items-center justify-between mb-2 space-x-2">
                    <label
                        htmlFor="message"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                        Նոր նամակ
                    </label>
                    <button
                        type="button"
                        onClick={() => chatCloseHandleClick()}
                        className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>

                <textarea
                    rows="6"
                    cols="80"
                    value={message ?? ""}
                    onChange={(event) => setMessage(event.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={
                        bigFile
                            ? "Ֆայլը չպետք է գերազանցի 5GB-ը"
                            : loading
                            ? "Բեռնվում է..."
                            : "Թողնել մեկնաբանություն..."
                    }
                />
            </div>
            <div className="flex justify-between items-center">
                <div className="py-3 w-full pr-2 ">
                    <SelectOption
                        isMulti
                        value={selected}
                        options={options}
                        onChange={onChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Ընտրել օգտվողներին"
                    />
                </div>
                <div className="w-2/4">
                    <FileUpload
                        setField={setFileName}
                        uploadFile={UserChat.uploadMessages}
                        setLoading={setLoading}
                        purpose={true}
                        setBigFile={setBigFile}
                    />
                    <span>{filename}</span>
                </div>
                <div>
                    <button
                        className="p-3 bg-blue-600 text-white rounded my-3"
                        onClick={sendMessage}
                        disabled={!selected.length && loading}
                    >
                        Ուղարկել
                    </button>
                </div>
            </div>
            <div>
                <span
                    ref={errorMessage}
                    className="text-xs text-red-400"
                ></span>
            </div>
        </div>
    );
});

export default NewMessage;
