import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useStore } from "../../store";

const DeleteByDate = ({ status, closeModal }) => {
    const [endDate, setEndDate] = useState();
    const { adminStore } = useStore();

    const approveHandler = () => {
        if (status === "meeting") {
            adminStore.deleteMeetings(endDate);
        } else if (status === "user_logs") {
            adminStore.deleteUserLogs(endDate);
        } else if (status === "message_logs") {
            adminStore.deleteMessageLogs(endDate);
        }
        closeModal();
    };

    return (
        <div className="flex flex-col">
            <DateTimePicker
                onChange={setEndDate}
                value={endDate}
                locale="hy-AM"
            />

            <div className="flex justify-center items-center">
                <button
                    onClick={() => approveHandler()}
                    className="bg-blue-600 text-white m-2 p-2 w-36 items-center"
                >
                    Հաստատել
                </button>
                <button
                    onClick={() => closeModal()}
                    className="bg-red-400 text-white m-2 p-2 w-36"
                >
                    Մերժել
                </button>
            </div>
        </div>
    );
};

export default DeleteByDate;
