import { useRef, useState, useContext, useEffect } from "react";
import VideoRoom from "../../states/VideoRoom";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import SelectOption from "../SelectOption";
import { useStore } from "../../store";
import { useCallback } from "react";
import { SocketContext } from "../../const/socket";
import SOCKET from "../../const/socketNames";
import { getDateByMonthAndHour } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../const/axiosInstance";
import Clock from "../Clock/Clock";

function Lobby({ setIsOpen }) {
    const [roomName, setRoomName] = useState("");
    const [date, setDate] = useState(new Date());
    const [hour, setHour] = useState("");
    const [selected, setSelected] = useState([]);
    const [requestedDate, setRequestedDate] = useState("");
    const [error, setError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const buttonDisabledRef = useRef();
    const users = JSON.parse(localStorage.getItem("users"));
    const [options] = useState(function () {
        var res = [];
        Object.keys(users).forEach((index) => {
            res = [
                ...res,
                {
                    value: users[index].username,
                    label:
                        users[index].name || users[index].last_name
                            ? users[index].name + " " + users[index].last_name
                            : users[index].username,
                },
            ];
        });
        return res;
    });
    const socket = useContext(SocketContext);
    const onChange = useCallback(
        (selectedOptions) => setSelected(selectedOptions),
        []
    );
    const countRef = useRef("");

    const chatCloseHandleClick = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const getTime = async () => {
            const date = await axiosInstance.get("/devices/time");
            setRequestedDate(date.data.data);
        };
        getTime();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        buttonDisabledRef.current.setAttribute("disabled", true);
        const invited_users = selected.map((el) => el.value);
        const newIsoDate = new Date(date.toISOString());
        newIsoDate.setHours(newIsoDate.getHours() + 4);
        const isoDate = new Date(
            getDateByMonthAndHour(newIsoDate.toISOString(), hour)
        );
        isoDate.setHours(isoDate.getHours() - 4);
        await VideoRoom.setVideoRoomByName({
            name: e.target.room.value,
            count: countRef.current.value,
            start_date: isoDate.toISOString(),
            purpose: "Group",
            invited_users,
        });
        await VideoRoom.getMyVideoMeetings();

        socket.emit(SOCKET.MEETING_CREATE, invited_users);

        chatCloseHandleClick();
    };

    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value);
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "1000px" }}>
            <div
                className="flex justify-center flex-col"
                onClick={() => setOpenModal(false)}
            >
                <div className="flex items-center justify-between space-x-2">
                    <h2 className="text-center	">Ստեղծել նոր հանդիպում</h2>
                    <button
                        type="button"
                        onClick={() => chatCloseHandleClick()}
                        className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faWindowClose} />
                    </button>
                </div>
                <div className="flex justify-between p-3">
                    <div className="flex mr-3  flex-col">
                        <div>
                            <label htmlFor="room" className="block">
                                Հանդիպման անունը
                            </label>
                            <input
                                type="text"
                                id="room"
                                name="room"
                                maxLength={30}
                                className="border-2 w-full mb-2"
                                value={roomName}
                                onChange={(e) => handleRoomNameChange(e)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="room" className="block">
                                Հանդիպման մասնակիցների քանակը
                            </label>
                            <input
                                type="number"
                                id="participant_count"
                                name="count"
                                disabled
                                ref={countRef}
                                value={selected.length}
                                className="border-2 w-full mb-2"
                                required
                            />
                        </div>
                        <div className="py-3 pr-2 ">
                            <SelectOption
                                isMulti
                                value={selected}
                                options={options}
                                onChange={onChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Ընտրել օգտվողներին"
                            />
                        </div>
                    </div>
                    <div>
                        <Calendar
                            value={date}
                            locale="hy-AM"
                            onChange={setDate}
                            minDate={new Date()}
                        />
                        <div style={{ display: "flex", "margin-top": "5px" }}>
                            <label htmlFor="appt">
                                Ընտրեք ձեր հանդիպման ժամը։{" "}
                            </label>
                            <Clock
                                setOpenModal={setOpenModal}
                                openModal={openModal}
                                date={date}
                                requestedDate={requestedDate}
                                setHour={setHour}
                                setError={setError}
                            />
                        </div>
                        {error && (
                            <div style={{ color: "red" }}>
                                Ժամը անհրաժեշտ է ընտրել սկսած{" "}
                                {`${
                                    new Date(requestedDate).getHours() < 10
                                        ? "0" +
                                          new Date(requestedDate).getHours()
                                        : new Date(requestedDate).getHours()
                                }:${
                                    new Date(requestedDate).getMinutes() < 10
                                        ? "0" +
                                          new Date(requestedDate).getMinutes()
                                        : new Date(requestedDate).getMinutes()
                                }֊ից`}
                            </div>
                        )}
                    </div>
                </div>
                <button
                    className="bg-blue-600 text-white h-15 w-15 mt-2"
                    disabled={error}
                    ref={buttonDisabledRef}
                    type="submit"
                >
                    Ընդունել
                </button>
            </div>
        </form>
    );
}

export default Lobby;
