import React, { useState } from "react";
import { BACKEND_URL } from "../../../const/Constants";
import userAvatar from "../../../assets/images/user_avatar.png";
import { Menu } from "@headlessui/react";
import CustomModal from "../../../components/modal/CustomModal";
import Users from "../../../states/Admin/Users";
import { useStore } from "../../../store";
import { ApproveOrCancel } from "../../../components/modal/ApproveOrCancel";
import SOCKET from "../../../const/socketNames";
import { socket } from "../../../const/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faUser } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { classNames } from "../../../utils/helpers";

const User = observer(({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const { adminStore, chatStore, userStore, usersStore } = useStore();

    const closeModal = () => {
        setIsOpen(false);
    };
    const deleteUserAccount = async () => {
        const result = await adminStore.deleteUserAccount(user._id);
        if (result.status) {
            adminStore.getUsers();
        }
        closeModal();
    };

    return (
        <li>
            <div className="flex items-center text-sm py-5 pl-5 h-auto text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out">
                <div className="shrink-0 relative">
                    <img
                        src={
                            user.image
                                ? `${BACKEND_URL}${user.image}`
                                : userAvatar
                        }
                        className="rounded-full w-10"
                        alt="Avatar"
                    />
                    <span className="flex absolute top-0 right-0"></span>
                    <span className="flex absolute bottom-0.5 right-0">
                        {usersStore.unviewedChats.includes(user.username) && (
                            <>
                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75" />
                                <span className="relative bg-red-400 h-3 w-3 rounded-full" />
                            </>
                        )}
                    </span>
                </div>
                {user.role === "Guest" && (
                    <FontAwesomeIcon
                        icon={faUser}
                        className="fa fa-user ml-3"
                        aria-hidden="true"
                    />
                )}

                <div className="grow w-full">
                    <Menu as="div" className="relative inline-block text-left">
                        <div className="flex px-5 py-2 space-x-5 text-center">
                            <div className="w-28 overflow-hidden">
                                {user.name || user.last_name
                                    ? `${user.name} ${user.last_name}`
                                    : user.username}
                            </div>

                            <Menu.Button className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100;">
                                <FontAwesomeIcon
                                    icon={faCogs}
                                    className="fa fa-cogs text-black"
                                    aria-hidden="true"
                                ></FontAwesomeIcon>
                            </Menu.Button>
                        </div>
                        <Menu.Items className="absolute z-10 w-46 rounded-md shadow-lg bg-white  focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() =>
                                                Users.changeContent(
                                                    "user_edit",
                                                    user.username
                                                )
                                            }
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                        >
                                            Խմբագրել
                                        </button>
                                    )}
                                </Menu.Item>
                                <hr />
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                            onClick={() =>
                                                Users.changeContent(
                                                    "user_log",
                                                    user.username
                                                )
                                            }
                                        >
                                            Ակտիվություն
                                        </button>
                                    )}
                                </Menu.Item>
                                <hr />
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                            onClick={() => {
                                                socket.emit(
                                                    SOCKET.CHAT_CREATE,
                                                    {
                                                        username:
                                                            userStore.username,
                                                        guestUsername:
                                                            user.username,
                                                    }
                                                );
                                                usersStore.removeUnviewedChats(
                                                    user.username
                                                );
                                                chatStore.setUsername(
                                                    user.username
                                                );
                                                socket.emit(
                                                    SOCKET.CHAT_VIEWED_ROOMS,
                                                    {
                                                        name: userStore.username,
                                                        caller: user.username,
                                                    }
                                                );
                                            }}
                                        >
                                            Նամակ
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                            )}
                                            onClick={() => {
                                                setDeleteUser(true);
                                                setIsOpen(true);
                                            }}
                                        >
                                            Ջնջել
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Menu>
                </div>
            </div>
            {deleteUser && (
                <CustomModal isOpen={isOpen} isClose={closeModal}>
                    <ApproveOrCancel
                        approveHandler={deleteUserAccount}
                        cancelHandler={closeModal}
                        reason_need={false}
                    />
                </CustomModal>
            )}
        </li>
    );
});

export default User;
