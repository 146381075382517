import React, { useEffect, useState } from "react";

export const MeetingTime = ({ start_date, requestedDate }) => {
    const [time, setTime] = useState();
    const [dateState, setDateState] = useState(0);

    useEffect(() => {
        setDateState(requestedDate);
    }, [requestedDate]);

    useEffect(() => {
        let totalSeconds = Math.floor(
            (new Date(Date.now() - dateState) - new Date(start_date)) / 1000
        );
        const timer = setInterval(function () {
            if (new Date(Date.now() - dateState) < new Date(start_date)) {
                const date = new Date(start_date).toString().split(" ");

                setTime(`Հանդիպումը կսկսվի ժամը ${date[4]} -ին`);
            } else {
                ++totalSeconds;
                let hour = Math.floor(totalSeconds / 3600);
                let minute = Math.floor((totalSeconds - hour * 3600) / 60);
                let seconds = totalSeconds - (hour * 3600 + minute * 60);
                if (hour < 10) hour = "0" + hour;
                if (minute < 10) minute = "0" + minute;
                if (seconds < 10) seconds = "0" + seconds;
                setTime(hour + ":" + minute + ":" + seconds);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [start_date, requestedDate]);

    return <>{time && <p className="text-white text-center">{time}</p>}</>;
};
