import React, { useState } from "react";

export const ApproveOrCancel = ({
    approveHandler,
    cancelHandler,
    title,
    reason_need = true,
}) => {
    const [reasonMessage, setReasonMessage] = useState("");

    return (
        <div className="flex flex-col">
            {reason_need && (
                <>
                    <label htmlFor="reason" className="text-center">
                        {title}
                    </label>
                    <input
                        id="reason"
                        placeholder="Պատճառը"
                        className="bg-gray-200 border-none m-0 p-2 pl-8 text-md rounded-2xl"
                        value={reasonMessage}
                        onChange={(e) => setReasonMessage(e.target.value)}
                    />
                </>
            )}
            <div className="flex justify-center items-center">
                <button
                    onClick={() => approveHandler(reasonMessage)}
                    className="bg-blue-600 text-white m-2 p-2 w-36 items-center"
                >
                    Հաստատել
                </button>
                <button
                    onClick={() => cancelHandler()}
                    className="bg-red-400 text-white m-2 p-2 w-36"
                >
                    Մերժել
                </button>
            </div>
        </div>
    );
};
