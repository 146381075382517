import React from "react";
import ChangePassword from "../components/ChangePassword";
import CheckDevices from "../components/checkDevices/checkDevicesStatus";
import UserForm from "../components/user/UserForm";

const Profile = () => {
    return (
        <div className="flex flex-wrap bg-white">
            <div className="basis-full flex">
                <div className="flex-1  border-r border-gray-400">
                    <UserForm />
                </div>
                <div className="change__password flex-1 ">
                    <ChangePassword />
                </div>
            </div>
            <div className="basis-full">
                <CheckDevices />
            </div>
        </div>
    );
};

export default Profile;
