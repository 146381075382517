import React, { useEffect, useState } from "react";
import "./clock.css";
import ModalClock from "./modalClock";

export default function Clock({
    date,
    requestedDate,
    setHour,
    setError,
    openModal,
    setOpenModal,
}) {
    const [value, setValue] = useState();
    const [hourModal, setHourModal] = useState("__");
    const [minutes, setMinutes] = useState("__");

    useEffect(() => {
        setHourModal(() =>
            new Date(requestedDate).getHours() < 10
                ? "0" + new Date(requestedDate).getHours()
                : new Date(requestedDate).getHours()
        );
        setMinutes(() =>
            new Date(requestedDate).getMinutes() < 10
                ? "0" + new Date(requestedDate).getMinutes()
                : new Date(requestedDate).getMinutes()
        );
    }, [requestedDate]);

    useEffect(() => {
        setValue(hourModal + ":" + minutes);
    }, [hourModal, minutes]);

    useEffect(() => {
        if (
            date.getMonth() == new Date(requestedDate).getMonth() &&
            date.getDate() == new Date(requestedDate).getDate()
        ) {
            if (
                `${
                    new Date(requestedDate).getHours() > 10
                        ? new Date(requestedDate).getHours()
                        : "0" + new Date(requestedDate).getHours()
                }:${
                    new Date(requestedDate).getMinutes() > 10
                        ? new Date(requestedDate).getMinutes()
                        : "0" + new Date(requestedDate).getMinutes()
                }` >
                hourModal + ":" + minutes
            ) {
                setError(true);
            } else {
                setError(false);
                setHour(value);
            }
        } else {
            setError(false);
            setHour(value);
        }
    }, [value, date.getDate(), date.getMonth()]);

    return (
        <div
            className="clock-div"
            onClick={(e) => {
                e.stopPropagation();
                setOpenModal(!openModal);
            }}
        >
            <input value={value} disabled required />
            <div>
                <img src="/475669.png" />
                {openModal && (
                    <ModalClock
                        setHour={setHourModal}
                        setMinutes={setMinutes}
                    />
                )}
            </div>
        </div>
    );
}
