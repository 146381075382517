import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { BACKEND_URL } from "../const/Constants";
import User from "../states/User";
import VideoRoom from "../states/VideoRoom";
import FileUpload from "./FileUpload";
import SOCKET from "../const/socketNames";
import { useStore } from "../store";
import { socket } from "../const/socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faUserCircle } from "@fortawesome/free-solid-svg-icons";

export const MeetingChat = ({ room_id }) => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [bigFile, setBigFile] = useState(false);
    const { videoRoomStore } = useStore();

    const sendMessage = () => {
        if (message.length !== 0) {
            socket.emit(SOCKET.MEETING_CHAT_SEND_MESSAGE, message);
            setMessage("");
            setTimeout(function () {
                scrollDown();
            }, 100);
        }
    };
    function scrollDown() {
        document.getElementsByClassName("main__chat_window")[0].scrollTop =
            document.getElementsByClassName(
                "main__chat_window"
            )[0].scrollHeight;
    }

    useEffect(() => {
        VideoRoom.getMeetingMessagesByRoomId(room_id).then((res) =>
            setMessages(res)
        );
        let otherTimer;

        socket.on(
            SOCKET.MEETING_CHAT_GET_MESSAGE,
            async (message, userName) => {
                await setMessages((prevstate) => [
                    ...prevstate,
                    { message, username: userName },
                ]);
                otherTimer = setTimeout(() => scrollDown(), 1000);
            }
        );
        const timer = setTimeout(() => scrollDown(), 1000);

        return () => {
            socket.off(SOCKET.MEETING_CHAT_GET_MESSAGE);
            clearTimeout(timer);
            if (otherTimer) {
                clearTimeout(otherTimer);
            }
        };
    }, []);

    const messageList = useMemo(
        () =>
            messages.map((el, ix) => (
                <div className="message" key={ix}>
                    <b>
                        <FontAwesomeIcon icon={faUserCircle} />
                        <span>
                            {" "}
                            {el.username === User.username
                                ? "me"
                                : videoRoomStore.getFullNameByUserName(
                                      el.username
                                  )}
                        </span>{" "}
                    </b>
                    <span className="break-words">
                        {el.message.includes("uploads") ? (
                            <a
                                href={`${BACKEND_URL}${el.message}`}
                                download
                                target="_blank"
                            >
                                <img
                                    src={`${BACKEND_URL}${el.message}`}
                                    alt={`${el.message.split("uploads")[1]}`}
                                />
                            </a>
                        ) : el.message.includes("http") ? (
                            <a href={el.message} target="_blank">
                                {el.message}
                            </a>
                        ) : (
                            el.message
                        )}
                    </span>
                </div>
            )),
        [messages]
    );

    return (
        <>
            <div className="main__chat_window overflow-hidden hover:overflow-y-scroll">
                <div id="messages" className="messages">
                    {messageList}
                </div>
            </div>
            <div className="main__message_container p-2 flex justift-center items-center">
                <FileUpload
                    setField={setMessage}
                    uploadFile={VideoRoom.uploadMessages}
                    setLoading={setLoading}
                    purpose={true}
                    setBigFile={setBigFile}
                />
                <textarea
                    id="chat_message"
                    rows={
                        Math.ceil(message.length / 25) < 4
                            ? Math.ceil(message.length / 25)
                            : 4
                    }
                    cols="100"
                    onChange={(e) => message.length < 4000 ? setMessage(e.target.value.trimStart()) : ""}
                    onKeyDown={(event) =>
                        event.key === "Enter" ? sendMessage() : ""
                    }
                    autoComplete="off"
                    value={message}
                    className="w-full resize-none focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"
                    placeholder={
                        bigFile
                            ? "Ֆայլը չպետք է գերազանցի 5GB-ը"
                            : loading
                            ? "Բեռնվում է..."
                            : "Հաղորդագրություն..."
                    }
                />
                <div id="send" className="options__button">
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                        className="fa fa-paper-plane"
                        aria-hidden="true"
                        disabled={loading}
                        onClick={sendMessage}
                    />
                </div>
            </div>
        </>
    );
};
