import React from "react";
import Modal from "react-modal";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const CustomModal = ({ children, isOpen, isClose }) => {
    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            onRequestClose={isClose}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Video Conference"
        >
            {children}
        </Modal>
    );
};

export default CustomModal;
