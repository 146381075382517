import userStore from "./User";
import chatStore from "./UserChat";
import videoRoomStore from "./VideoRoom";
import adminStore from "./Admin/Users";
import usersStore from "./Users";

const rootStore = {
  userStore,
  usersStore,
  chatStore,
  videoRoomStore,
  adminStore,
};

export default rootStore;
