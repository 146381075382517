import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DeleteMessageRoom = () => {
    return (
        <div className="flex items-end">
            <FontAwesomeIcon icon={faTrash} className="text-red-400" />
        </div>
    );
};

export default DeleteMessageRoom;
