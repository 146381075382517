import React, { useEffect, useState, memo } from "react";
import VideoRoom from "../states/VideoRoom";
import { Menu } from "@headlessui/react";
import AlertComponent from "../utils/Alert";
import {
    ALERT_MESSAGE_TYPE_DANGER,
    ALERT_MESSAGE_TYPE_SUCCESS,
} from "../const/Constants";
import Modal from "../components/modal/CustomModal";
import ChangeRoom from "../components/modal/ChangeRoom";
import { observer } from "mobx-react-lite";
import { ApproveOrCancel } from "../components/modal/ApproveOrCancel";
import { socket } from "../const/socket";
import SOCKET from "../const/socketNames";
import User from "../states/User";
import {
    getRoomIconByStatus,
    options,
    classNames,
    getClassByStatus,
    popUp,
} from "../utils/helpers";
import { useStore } from "../store";

const MeetingsList = observer(({ title, requestedDate }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [changeMeeting, setChangeMeeting] = useState(false);
    const [decline, setDecline] = useState(false);
    const [deleteMeeting, setDeleteMeeting] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [dateState, setDateState] = useState(0);
    const { userStore } = useStore();

    useEffect(() => {
        socket.on(SOCKET.DECLINE_ME_INVITE_MEETING, () => {
            VideoRoom.getMyVideoMeetings();
        });
    }, []);

    useEffect(() => {
        setDateState(requestedDate);
    }, [requestedDate]);

    useEffect(() => {
        VideoRoom.getMyVideoMeetings().then((r) => r);
    }, [selectedRoom]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setDecline(false);
        setDeleteMeeting(false);
        setChangeMeeting(false);
    }

    const connectMeeting = async (roomId, start_date) => {
        if (
            new Date(Date.now() - dateState).toISOString() <
            new Date(
                new Date(start_date).setMinutes(
                    new Date(start_date).getMinutes() - 10
                )
            ).toISOString()
        ) {
            const date = new Date(start_date).toString().split(" ");
            AlertComponent(
                ALERT_MESSAGE_TYPE_DANGER,
                `Հանդիպումը կսկսվի ${date[1]} ${date[2]}   ամսաթվի ժամը ${date[4]} -ին, որին կարող եք միանալ արավելագույնը 10 րոպե շուտ `
            );
        } else {
            const [myHasBusy] = await Promise.all([
                userStore.checkUserIsBusy({ username: userStore.username }),
            ]);

            if (!myHasBusy.status) {
                AlertComponent(
                    ALERT_MESSAGE_TYPE_DANGER,
                    "Դուք արդեն զանգի մեջ եք"
                );
                return;
            }

            socket.emit(SOCKET.MEETING_STARTED, roomId);
            popUp(`/video-room/${roomId}?purpose=Group`);
        }
    };

    const approveVideoMeetingDecline = async (reasonMessage) => {
        const result = await VideoRoom.declineVideoMeeting(selectedRoom["_id"]);
        const status = result.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        AlertComponent(status, result.message);
        socket.emit(
            SOCKET.APPROVE_OR_DECLINE_MEETING,
            selectedRoom.invited_users,
            reasonMessage,
            User.username
        );
        setSelectedRoom({});
        closeModal();
    };

    const approveMeetingDelete = async () => {
        const result = await VideoRoom.deleteVideoRoomById({
            roomId: selectedRoom._id,
        });
        const status = result.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        AlertComponent(status, result.message);
        socket.emit(
            SOCKET.APPROVE_OR_DECLINE_MEETING,
            selectedRoom.invited_users
        );
        if (result.status) {
            await VideoRoom.getMyVideoMeetings();
        }
        setSelectedRoom({});

        closeModal();
    };

    const checkLocaleTime = (roomDate, type) => {
        if (type === "pending") {
            // const roomTime = new Date(roomDate).toLocaleTimeString('en', {
            //     day:'numeric',
            //     month:'numeric',
            //     year:'numeric',
            //     hour: 'numeric',
            //     hour12: false,
            //     minute: 'numeric'
            // });
            // return dateState.toLocaleTimeString('en', {
            //     day:'numeric',
            //     month:'numeric',
            //     year:'numeric',
            //     hour: 'numeric',
            //     hour12: false,
            //     minute: 'numeric'
            // }) > roomTime;
            return new Date(Date.now() - dateState).toISOString() > roomDate;
        } else return false;
    };

    return (
        <div className="flex flex-col border-r border-slate-300 ">
            <div className="border-b p-3">
                <span className="font-bold">{title}</span>
            </div>
            <table className="border-collapse border border-slate-400 w-full">
                <thead>
                    <tr>
                        <th className="border border-slate-300 ">Անվանում</th>
                        <th className="border border-slate-300 ">Մասնակից</th>
                        <th className="border border-slate-300 ">Ժամ</th>
                        <th className="border border-slate-300 ">Վիճակ</th>
                    </tr>
                </thead>
                <tbody>
                    {VideoRoom.my_meetings.map((room, idx) => (
                        <Menu
                            key={idx}
                            as="tr"
                            className={
                                "relative p-1 border text-gray-500 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer " +
                                getClassByStatus(
                                    checkLocaleTime(
                                        room.start_date,
                                        room.status
                                    )
                                        ? "warning"
                                        : room.status
                                )
                            }
                        >
                            <Menu.Button
                                as={"td"}
                                className="border border-slate-300 "
                            >
                                {room.name}
                            </Menu.Button>
                            <Menu.Button
                                as={"td"}
                                className="border border-slate-300 "
                            >
                                {room.invited_users.length + 1}
                            </Menu.Button>
                            <Menu.Button
                                as={"td"}
                                className="border border-slate-300 "
                            >
                                {new Date(room.start_date).toLocaleDateString(
                                    "en-GB",
                                    options
                                )}
                            </Menu.Button>
                            <Menu.Button
                                as={"td"}
                                className="border border-slate-300 "
                            >
                                {getRoomIconByStatus(room.status)}
                            </Menu.Button>
                            <Menu.Items
                                as={"td"}
                                className="absolute z-10 w-40 right-0 rounded-md shadow-lg bg-white  focus:outline-none"
                            >
                                <div className="py-1">
                                    {(room.status === "pending" ||
                                        room.status === "started") && (
                                        <>
                                            {
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() =>
                                                                connectMeeting(
                                                                    room._id,
                                                                    room.start_date
                                                                )
                                                            }
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100 text-gray-900"
                                                                    : "text-gray-700",
                                                                "block px-4 py-2 text-sm"
                                                            )}
                                                        >
                                                            Մասնակցել
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            }
                                            <hr />
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => {
                                                            setChangeMeeting(
                                                                true
                                                            );
                                                            setSelectedRoom(
                                                                room
                                                            );
                                                            openModal();
                                                        }}
                                                        className={classNames(
                                                            active
                                                                ? "bg-gray-100 text-gray-900"
                                                                : "text-gray-700",
                                                            "block px-4 py-2 text-sm"
                                                        )}
                                                    >
                                                        Փոփոխել
                                                    </button>
                                                )}
                                            </Menu.Item>
                                            <hr />
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => {
                                                            setDecline(true);
                                                            setSelectedRoom(
                                                                room
                                                            );
                                                            openModal();
                                                        }}
                                                        className={classNames(
                                                            active
                                                                ? "bg-gray-100 text-red-500"
                                                                : "text-red-700",
                                                            "block px-4 py-2 text-sm"
                                                        )}
                                                    >
                                                        Չեղարկել
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </>
                                    )}

                                    <hr />
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setDeleteMeeting(true);
                                                    setSelectedRoom(room);
                                                    openModal();
                                                }}
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-red-500"
                                                        : "text-red-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                            >
                                                Ջնջել
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Menu>
                    ))}
                </tbody>
            </table>

            {changeMeeting && (
                <Modal isClose={() => closeModal()} isOpen={modalIsOpen}>
                    <ChangeRoom
                        room={selectedRoom}
                        closeModal={closeModal}
                        setIsOpen={setIsOpen}
                    />
                </Modal>
            )}

            {decline && (
                <Modal isClose={() => closeModal()} isOpen={modalIsOpen}>
                    <ApproveOrCancel
                        approveHandler={approveVideoMeetingDecline}
                        cancelHandler={closeModal}
                        title="Չեղարկելու պատճառը"
                    />
                </Modal>
            )}
            {deleteMeeting && (
                <Modal isClose={() => closeModal()} isOpen={modalIsOpen}>
                    <ApproveOrCancel
                        approveHandler={approveMeetingDelete}
                        cancelHandler={closeModal}
                        reason_need={false}
                    />
                </Modal>
            )}
        </div>
    );
});

export default memo(MeetingsList);
