import { useEffect, useState } from "react";
import CreateRoom from "../components/modal/CreateRoom";
import Modal from "../components/modal/CustomModal";
import avatarImage from "../assets/images/user_avatar.png";
import customerImage from "../assets/images/customer-service.png";
import LogOutSvg from "../components/svg/LogOutSvg";
import UpcomingMeetingList from "./UpcomingMeetingsList";
import MeetingsList from "./MeetingsList";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import NewMessage from "../components/modal/NewMessage";
import { BACKEND_URL } from "../const/Constants";
import { socket } from "../const/socket";
import SOCKET from "../const/socketNames";
import { useStore } from "../store";
import { observer } from "mobx-react-lite";
import { popUp } from "../utils/helpers";
import { axiosInstance } from "../const/axiosInstance";

const General = observer(() => {
    const { userStore, chatStore } = useStore();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [requestedDate, setRequestedDate] = useState(0);

    const [navbar, setNavbar] = useState("");
    const [purpose, setPurpose] = useState("create");

    const navigate = useNavigate();

    function openModal(info) {
        setPurpose(info);
        setIsOpen(true);
    }

    const logout = async () => {
        await userStore.logout();
        socket.disconnect();
        navigate("/");
    };

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        chatStore.getAdminUnreadMessages();
    }, []);

    useEffect(() => {
        socket.on(SOCKET.CHAT_ROOM_INFO_ADMIN, async (room) => {
            if (room.type === "Admin")
                popUp(`/chat?username=Admin&roomId=${room.id}`);
        });

        socket.on(SOCKET.CHAT_ROOM_INFO_NEW_TAB, async (room) => {
            if (room.type === "blank")
                popUp(`/chat?username=${room.username}&roomId=${room.id}`);
        });

        return () => {
            socket.off(SOCKET.CHAT_ROOM_INFO_ADMIN);
            socket.off(SOCKET.CHAT_ROOM_INFO);
        };
    }, []);

    useEffect(() => {
        const date = async () => {
            const date = await axiosInstance.get("/devices/time");
            setRequestedDate(Date.now() - new Date(date.data.data));
        };
        date();
        localStorage.setItem("user", userStore.username);
    }, []);

    useEffect(() => {
        socket.on(SOCKET.CHAT_UNVIEWED_ROOMS, (data) => {
            if (data.role === "Admin") {
                chatStore.setAdminChat(true);
            }
        });
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            if (data.username === "Admin") {
                chatStore.setAdminChat(false);
            }
        });

        return () => {
            socket.off(SOCKET.CHAT_UNVIEWED_ROOMS);
            socket.off(SOCKET.CHAT_REFRESH_ROOMS);
        };
    }, []);

    const changeNavbar = () => {
        setNavbar("profile");
    };

    const chatHandleClick = () => {
        if (chatStore.username !== "ALL") {
            socket.emit(SOCKET.CHAT_CLOSE, {
                username: userStore.username,
                guestUsername: chatStore.username,
            });
            chatStore.clearUsername();
            chatStore.clearMessages();
            chatStore.setUsername("ALL");
        }
    };

    return (
        <div className="flex flex-col w-full home">
            <div className="flex w-full justify-between items-center p-3 border-b border-gray-200 h-16 bg-white">
                <div className="shrink-0 cursor-pointer">
                    <img
                        src={
                            userStore.imagePath
                                ? `${BACKEND_URL}${userStore.imagePath}`
                                : avatarImage
                        }
                        className="p-1 w-10 h-10 rounded-full ring-2 ring-blue-300 dark:ring-blue-500"
                        style={{ borderRadius: "50%" }}
                        alt="Avatar"
                        onClick={
                            navbar !== "profile"
                                ? changeNavbar
                                : () => setNavbar("")
                        }
                    />
                </div>
                <div className="w-full text-start ">
                    <p className="text-sm px-2 font-semibold text-blue-600">
                        {userStore.username}
                        <span className="text-xs text-black">
                            ( {userStore.userData.name}{" "}
                            {userStore.userData.last_name}{" "}
                            {userStore.userData.father_name} )
                        </span>
                    </p>
                </div>

                <div className="flex items-center ">
                    <button
                        onClick={() => chatHandleClick()}
                        className="bg-blue-600  text-white h-5 w-32 rounded-lg m-2  text-sm"
                    >
                        Նամակներ
                    </button>
                    <button
                        onClick={() => openModal("chat")}
                        className="bg-blue-600 text-white h-5 w-32 rounded-lg m-2  text-sm"
                    >
                        Նոր Նամակ
                    </button>
                    {userStore.user_settings.create_meeting && (
                        <button
                            onClick={() => openModal("create")}
                            className="bg-blue-600 text-white h-5 w-32 rounded-lg m-2  text-sm"
                        >
                            Նոր Կոնֆերանս
                        </button>
                    )}

                    <div className="relative">
                        {chatStore.adminChat && (
                            <span className="absolute right-2 bg-red-400 h-3 w-3 rounded-full" />
                        )}
                        <img
                            src={customerImage}
                            className="fa-2xl cursor-pointer w-10 ml-3 mr-5"
                            onClick={() => {
                                socket.emit(SOCKET.CHAT_CREATE, {
                                    username: userStore.username,
                                    guestUsername: "Admin",
                                });
                                chatStore.setAdminChat(false);
                                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                                    name: userStore.username,
                                    caller: "Admin",
                                });
                            }}
                            alt="support"
                        />
                    </div>
                    <div onClick={() => logout()}>
                        <LogOutSvg />
                    </div>
                </div>
            </div>
            {navbar === "profile" ? (
                <Profile />
            ) : (
                <div className="flex h-full border-gray-200 border overflow-hidden">
                    <div className="w-1/2 bg-white overflow-auto ">
                        <MeetingsList
                            title="Իմ հրավերները"
                            requestedDate={requestedDate}
                        />
                    </div>
                    <div className="w-1/2 bg-white overflow-auto">
                        <UpcomingMeetingList
                            title="Հրավերները ինձ"
                            requestedDate={requestedDate}
                        />
                    </div>
                </div>
            )}

            <div>
                <div className="">
                    <Modal isClose={() => closeModal()} isOpen={modalIsOpen}>
                        {(function () {
                            switch (purpose) {
                                case "create":
                                    return <CreateRoom setIsOpen={setIsOpen} />;
                                default:
                                    return <NewMessage setIsOpen={setIsOpen} />;
                            }
                        })()}
                    </Modal>
                </div>
            </div>
        </div>
    );
});

export default General;
