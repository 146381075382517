import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import FilterMessages from "../components/FilterMessages";
import {useStore} from "../../../store";
import {options} from "../../../utils/helpers";

const MessageList = observer(() => {

    const {adminStore} = useStore()

    // useEffect(() => {
    //     adminStore.resetMessageLogs()
    // }, [adminStore.active_username])

    return (
        <div className="flex flex-col    basis-9/12">
            <div className='border-b p-3'>
                <span className='font-bold'>Նամակների ցուցակ</span>
            </div>
            <FilterMessages/>
            <div className='overflow-y-scroll' style={{maxHeight: "70vh"}}>
                <table className="border-collapse border border-l-0  border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 border-l-0">Տեսակ</th>
                        {/*<th className="border border-slate-300 ">Մասնակից</th>*/}
                        <th className="border border-slate-300 ">Ժամ</th>
                        <th className="border border-slate-300 ">Չափը</th>
                    </tr>
                    </thead>
                    <tbody>
                    {adminStore.messages_logs.map((message, idx) => (
                        <tr key={message._id}>
                            <td className='border border-slate-300 border-l-0'>{message.type === "Message"? "Նամակ": "Ֆայլ"}</td>
                            {/*<td className='border border-slate-300 border-l-0'>Սաշաին</td>*/}
                    <td className='border border-slate-300 border-l-0'>{new Date(message.created_at).toLocaleDateString('en-GB', options)}</td>
                    <td className='border border-slate-300 border-l-0'>{message.size}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
});

export default MessageList;