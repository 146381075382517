import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { NotificationContainer } from "react-notifications";
import { useStore } from "../store";

import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants";
import { passwordValidation, usernameValidation } from "../utils/validate";
import AlertComponent from "../utils/Alert";
import { axiosInstance } from "../const/axiosInstance";

const Login = observer(() => {
    const { userStore } = useStore();
    const [data, setData] = useState({
        username: "",
        password: "",
        confirm_password: "",
    });

    const [passwordDeleted, setPasswordDeleted] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrorMessage("");

        if (name === "username") {
            const result = usernameValidation(value);
            !result.isValid && setErrorMessage(result.message);
        } else if (name === "confirm_password") {
            data.password !== value &&
                setErrorMessage("Գաղտնաբառը չի համընկնում");
        } else {
            const result = passwordValidation(value);
            !result.isValid && setErrorMessage(result.message);
        }
    };

    const refreshPassword = async () => {
        const result = await userStore.refreshPassword({
            password: data.password,
        });
        if (result.status) {
            await join();
        } else {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message);
        }
    };

    async function join() {
        const { username, password } = data;
        if (!username && !password) {
            setErrorMessage("Անհրաժեշտ է լրացնել դաշտերը");
            return;
        }
        if (password.length < 8) {
            setErrorMessage("Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ");
            return;
        }
        const result = await userStore.login({
            username,
            password,
        });
        if (!result.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message);
        } else {
            await userStore.getCurrentUser();
            if (userStore.user_settings.password_deleted) {
                setPasswordDeleted(true);
                setData({
                    ...data,
                    password: "",
                    confirm_password: "",
                });
            } else {
                userStore.setUsername(username);
                window.location = "/home";
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => window.removeEventListener("keydown", onKeyDown);
    });
    function onKeyDown(e) {
        e.key === "Enter" && join();
    }

    useEffect(() => {
        let isExist = window.localStorage.getItem("username");
        let session = window.localStorage.getItem("session");
        if (isExist && session) {
            let result = (async () =>
                await axiosInstance.post("/devices/session", {
                    session,
                }))();
            result.then((d) => {
                if (d.data.status) {
                    if ((isExist = "Admin")) {
                        window.location = "/dashboard";
                    } else {
                        window.location = "/home";
                    }
                }
            });
        }
    });

    return (
        <div className=" w-full App-header relative min-h-screen flex flex-col sm:justify-center items-center">
            <div className="relative w-2/4">
                <div className="relative w-full rounded-3xl flex justify-around items-center p-6 bg-gray-100 shadow-md">
                    <div className="w-2/4">
                        <div>
                            <img
                                src={require("../assets/images/logo.png")}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="w-2/3">
                        <label className="block m-3 text-sm text-gray-700 text-center font-semibold">
                            ՏԵՍԱԿՈՆՖԵՐԵՆՍ ԿԱՊ
                        </label>
                        {passwordDeleted ? (
                            <>
                                <div>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Գաղտնաբառ"
                                        value={data.password}
                                        onChange={(e) => handleChange(e)}
                                        className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        placeholder="Հաստատել Գաղտնաբառ"
                                        value={data.confirm_password}
                                        onChange={(e) => handleChange(e)}
                                        className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                                    />
                                </div>
                                <div></div>
                                {errorMessage === "" ? null : (
                                    <span className="text-red-500 text-sm text-center">
                                        {errorMessage}
                                    </span>
                                )}
                                <div className="mt-7 w-full flex justify-center items-center">
                                    <div className="w-full">
                                        <button
                                            onClick={() => refreshPassword()}
                                            disabled={!!errorMessage}
                                            className="bg-blue-500  p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                        >
                                            Փոփոխել գաղտնաբառը
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Անուն"
                                        value={data.username}
                                        onChange={(e) => handleChange(e)}
                                        className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Գաղտնաբառ"
                                        value={data.password}
                                        onChange={(e) => handleChange(e)}
                                        className="mt-1 pl-2 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                                    />
                                </div>
                                <div></div>
                                {errorMessage === "" ? null : (
                                    <span className="text-red-500 text-sm text-center">
                                        {errorMessage}
                                    </span>
                                )}
                                <div className="mt-7 w-full flex justify-center items-center">
                                    <div className="w-full">
                                        <button
                                            onClick={() => join()}
                                            disabled={!!errorMessage}
                                            className="bg-blue-500  p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                        >
                                            Մուտք գործել
                                        </button>
                                        <a
                                            href="/support.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="ml-2 text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                        >
                                            Օգնություն
                                        </a>
                                    </div>
                                    <div className="">
                                        <a
                                            href="/register"
                                            className=" text-blue-500 transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                                        >
                                            Գրանցվել
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                        <NotificationContainer />
                    </div>
                </div>
            </div>
        </div>
    );
});
export default Login;
