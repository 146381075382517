import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import SideNav from "../components/SideNav";
import SingleChat from "../components/PrivateChat";
import { useStore } from "../store";
import Modal from "../components/modal/CustomModal";
import General from "../container/General";
import SOCKET from "../const/socketNames";
import { AnswerCall } from "../components/modal/AnswerCall";
import User from "../states/User";
import { useNavigate } from "react-router-dom";
import { popUp } from "../utils/helpers";
import { SocketContext } from "../const/socket.js";

const Home = observer(() => {
    const [roomId, setRoomId] = useState("");
    const [name, setName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const navigate = useNavigate();
    const [openP2P, setOpenP2P] = useState(false);
    const socket = useContext(SocketContext);

    const { usersStore, userStore, chatStore, videoRoomStore } = useStore();

    useEffect(function () {
        if (userStore.role === "Guest") {
            userStore.logout();
            navigate("/");
        } else {
            socket.emit(SOCKET.JOIN_CONNECTED, userStore.user);
            if (!window.localStorage.getItem("existRoom")) {
                videoRoomStore.deleteMyOnlineStatus();
            }
            usersStore.getUsers();
        }
    }, []);

    useEffect(() => {
        socket.on(SOCKET.LOGOUT_FIRST_ENTER, () => {
            userStore.logout(true);
            navigate("/");
        });
    }, [socket]);

    function closeP2P() {
        setOpenP2P(false);
    }

    useEffect(function () {
        socket.on(SOCKET.PAGE_REFRESH, () => {
            window.location.reload();
        });
        socket.on(SOCKET.PRIVATE_CALL_WAIT, ({ name, room_id }) => {
            userStore.setReceivedCall(true);
            setRoomId(room_id);
            setName(name);
            openModal();
        });

        socket.on(SOCKET.SHUT_DOWN_CALL, () => {
            setIsOpen(false);
            window.location.reload();
        });

        return () => {
            socket.off(SOCKET.PRIVATE_CALL_WAIT);
            socket.off(SOCKET.SHUT_DOWN_CALL);
            socket.off(SOCKET.PAGE_REFRESH);
        };
    }, []);

    useEffect(() => {
        socket.on(SOCKET.OPEN_P2P_CHAT, ({ user, connectWith }) => {
            setOpenP2P(true);
            chatStore.setUsername(user);
        });
    });

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        socket.emit(
            SOCKET.APPROVE_OR_CANCEL_PRIVATE_CALL,
            "cancel",
            name,
            User.username
        );
        socket.emit(SOCKET.SHUT_DOWN_CALL, name, User.username);
        setIsOpen(false);
    }

    const answerCall = () => {
        socket.emit(
            SOCKET.APPROVE_OR_CANCEL_PRIVATE_CALL,
            "approve",
            name,
            userStore.username
        );
        setIsOpen(false);
        popUp(`/video-room/${roomId}`);
    };

    return (
        <div className="App flex ">
            <div className="flex h-full">
                <SideNav setSelected={setSelected} />
            </div>
            <General />

            {userStore.receivingCall ? (
                <Modal isOpen={isOpen} isClose={closeModal}>
                    <AnswerCall
                        answerCall={answerCall}
                        closeModal={closeModal}
                        name={usersStore.fullName(
                            usersStore.users.find((el) => el.username === name)
                        )}
                    />
                </Modal>
            ) : null}

            {chatStore.username && <SingleChat selected={selected} />}
            <NotificationContainer />
        </div>
    );
});

export default Home;
