import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { socket } from "../const/socket";
import UserElement from "./user/UserElement";
import LoadingSpinner from "./Loading";
import SOCKET from "../const/socketNames";
import CustomModal from "./modal/CustomModal";
import { useStore } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import emailClose from "../assets/images/email.png";
import emailOpen from "../assets/images/email_open.png";
import User from "../states/User";
import { popUp } from "../utils/helpers";

const SideNav = observer(({ setSelected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [waitAnswerCall, setWaitAnswerCall] = useState(false);
    const [callStatus, setCallStatus] = useState(true);
    const [search, setSearch] = useState("")

    const { userStore, usersStore, videoRoomStore, chatStore } = useStore();

    useEffect(() => {
        usersStore.getUsers("")
        const timer = setInterval(() => {
            usersStore.getUsers();
        }, 300000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => setSelected(selectedUser), [selectedUser]);

    function closeModal() {
        setCallStatus(true);
        setIsOpen(false);
        setWaitAnswerCall(false);
        setSelectedUser("");
        window.removeEventListener("beforeunload", cancelCall);
        const isCreateMessage = true;
        socket.emit(
            SOCKET.SHUT_DOWN_CALL,
            selectedUser,
            User.username,
            isCreateMessage,
            videoRoomStore.roomId
        );
    }

    function cancelCall(event) {
        if (waitAnswerCall) {
            setIsOpen(false);
            socket.emit(
                SOCKET.SHUT_DOWN_CALL,
                selectedUser,
                User.username,
                false
            );
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", cancelCall);
        return () => window.removeEventListener("unload", cancelCall);
    }, [waitAnswerCall]);

    useEffect(() => {
        socket.on(SOCKET.CHAT_UNVIEWED_ROOMS, (data) => {
            if (data.role === "Admin") {
                chatStore.setAdminChat(true);
            } else {
                usersStore.setUnviewedChats(data.username);
            }
        });
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            usersStore.removeUnviewedChats(userStore.username);
        });
        socket.on(SOCKET.USERS_ONLINE_LIST, (users) =>
            usersStore.setOnlineUsersList(users)
        );

        return () => {
            socket.off(SOCKET.CHAT_UNVIEWED_ROOMS);
            socket.off(SOCKET.CHAT_REFRESH_ROOMS);
            socket.off(SOCKET.USERS_ONLINE_LIST);
        };
    }, []);

    useEffect(() => {
        let timer = null;
        if (waitAnswerCall) {
            timer = setTimeout(() => {
                closeModal();
            }, 60000);
        }
        if (!waitAnswerCall) {
            clearTimeout(timer);
        }
        return () => clearTimeout(timer);
    }, [waitAnswerCall]);

    useEffect(() => {
        socket.on(SOCKET.PRIVATE_CALL_ANSWER_APPROVE, () => {
            const joinCall = document.getElementById("joinCall");
            joinCall.removeAttribute("disabled");
            setCallStatus(false);
            joinCall.addEventListener("click", joinCallHandle);
            const audio = document.getElementById("audioCall");
            audio.pause();
        });

        socket.on(SOCKET.PRIVATE_CALL_ANSWER_CANCEL, () => {
            setIsOpen(false);
            setCallStatus(true);
        });

        return () => {
            socket.off(SOCKET.PRIVATE_CALL_ANSWER_APPROVE);
            socket.off(SOCKET.PRIVATE_CALL_ANSWER_CANCEL);
        };
    }, []);

    useEffect(() => {
        usersStore.getUnreadMessages();
        userStore.role && setLoading(false);
    }, []);

    const users = usersStore.usersList.filter((el) => el.username.includes(search)).map((user) => (
        <UserElement
            key={user._id}
            user={user}
            status={
                usersStore.onlineUsers.includes(user.username) &&
                userStore.username
            }
            setWaitAnswerCall={setWaitAnswerCall}
            setSelectedUser={setSelectedUser}
            setIsOpen={setIsOpen}
        />
    ));

    const getUsersBySearch = (e) => {
        setSearch(e.target.value)
    };

    const getUnreadMessage = () => {
        if (!sort) {
            usersStore.viewedNewMessages();
            setSort(!sort);
        } else {
            usersStore.getUsers("");
            setSort(!sort);
        }
    };

    const joinCallHandle = () => {
        setIsOpen(false);
        setWaitAnswerCall(false);
        setCallStatus(true);
        if (videoRoomStore.roomId) {
            popUp(`/video-room/${videoRoomStore.roomId}?status=caller`);
        }
    };

    return (
        <div className="flex flex-col  min-w-fit border-gray-200 w-72  border-r">
            <div className="border-gray-200 border-b  py-1 flex justify-center  w-full">
                <span className="font-bold ">Օգտվողներ</span>
                {usersStore.unviewedChats.length > 0 && (
                    <span className="bg-red-400 h-3 w-3 rounded-full m-2" />
                )}
            </div>
            <ul className="overflow-y-scroll h-full">
                {loading ? (
                    <div className="flex justify-center items-center mt-10">
                        <LoadingSpinner />
                    </div>
                ) : (
                    users
                )}
            </ul>
            <div className=" border-blue-500 rounded-3xl my-3 ml-3 flex relative">
                <input
                    onChange={(e) => getUsersBySearch(e)}
                    className="bg-gray-200 border-none m-0 p-2 w-9/12  text-md rounded-2xl"
                    type="search"
                    value={search}
                    placeholder="Որոնում..."
                />
                {!sort ? (
                    <img
                        src={emailClose}
                        className="w-10 ml-3  cursor-pointer"
                        onClick={getUnreadMessage}
                        alt=""
                    />
                ) : (
                    <img
                        src={emailOpen}
                        className="w-10 ml-3 cursor-pointer"
                        onClick={getUnreadMessage}
                        alt=""
                    />
                )}
            </div>
            {waitAnswerCall && (
                <CustomModal isClose={closeModal} isOpen={isOpen}>
                    <div className="flex justify-between items-center">
                        <div className="mr-3 flex flex-col">
                            <input
                                className="bg-green-400 w-64 h-10 disabled:bg-red-100 cursor-pointer"
                                id="joinCall"
                                type="button"
                                value={
                                    callStatus
                                        ? "Սպասվում է պատասխան"
                                        : "Միանալ"
                                }
                                disabled
                            />
                        </div>
                        <audio
                            controls
                            autoPlay
                            style={{ display: "none" }}
                            id="audioCall"
                        >
                            <source src="/gudok.mp3" type="audio/mp3" />
                        </audio>

                        <button
                            type="button"
                            onClick={() => closeModal(true)}
                            className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faWindowClose} />
                        </button>
                    </div>
                </CustomModal>
            )}
        </div>
    );
});

export default SideNav;
