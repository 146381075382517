import React, {useState} from 'react';
import DateTimePicker from "react-datetime-picker";
import {useStore} from "../../../store";
import DeleteContent from "./DeleteContent";

const FilterMessages = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [type, setType] = useState([])

    const {adminStore} = useStore()

    const filterMessages = (e) => {
        e.preventDefault()
        adminStore.getMessagesLogs(startDate, endDate, type)
    }

    const handleChange = (e) => {
        if (e.target.checked) {
            if (!type.includes(e.target.name)) {
                setType([...type, e.target.name])
            }
        } else {
            if (type.includes(e.target.name)) {
                setType(type.filter(el => el !== e.target.name))
            }
        }
    }
    return (
        <form onSubmit={filterMessages} className="max-h-36 min-h-36">
            <div className="flex justify-center p-3">
                <div>
                    <DateTimePicker onChange={setStartDate} value={startDate} locale="hy-AM"/>
                </div>
                <span className=" px-3">Մինչև</span>
                <div>
                    <DateTimePicker onChange={setEndDate} value={endDate} locale="hy-AM"/>
                </div>
            </div>
            <div className="flex justify-between w-1/3 m-auto">
                <div>
                    <h3>Ֆայլեր</h3>
                    <input type="checkbox" name="File" onChange={handleChange}/>
                </div>
                <div>
                    <h3>Նամակներ</h3>
                    <input type="checkbox" name="Message" onChange={handleChange}/>
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <DeleteContent status="message_logs" startdate={startDate} enddate={endDate} type={type}/>

                <button type="submit" className="bg-green-300 h-5 w-36 rounded-lg m-2  text-sm cursor-pointer ">Որոնում</button>
            </div>

        </form>
    );
};

export default FilterMessages;