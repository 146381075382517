import { useContext, memo } from "react";
import { Menu } from "@headlessui/react";
import { observer } from "mobx-react-lite";
import { SocketContext } from "../../const/socket";
import userAvatar from "../../assets/images/user_avatar.png";
import { useStore } from "../../store";
import { BACKEND_URL } from "../../const/Constants";
import SOCKET from "../../const/socketNames";
import AlertComponent from "../../utils/Alert";
import { ALERT_MESSAGE_TYPE_DANGER } from "../../const/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let style = {
    width: "100%",
};

const UserElement = observer(
    ({ user, status, setIsOpen, setSelectedUser, setWaitAnswerCall }) => {
        const { userStore, chatStore, usersStore, videoRoomStore } = useStore();
        const socket = useContext(SocketContext);

        const chatHandleClick = () => {
            socket.emit(SOCKET.CHAT_CREATE, {
                username: userStore.username,
                guestUsername: user.username,
            });
            usersStore.removeUnviewedChats(user.username);
            chatStore.setUsername(user.username);
            socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                name: userStore.username,
                caller: user.username,
            });
        };

        const chatNewTabHandleClick = () => {
            socket.emit(SOCKET.CHAT_CREATE, {
                username: userStore.username,
                guestUsername: user.username,
                type: "blank",
            });
            usersStore.removeUnviewedChats(user.username);
            socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                name: userStore.username,
                caller: user.username,
            });
        };

        const videoCallHandleClick = async (username) => {
            const [result, myHasBusy] = await Promise.all([
                userStore.checkUserIsBusy({ username }),
                userStore.checkUserIsBusy({ username: userStore.username }),
            ]);

            if (!result.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, "Օգտվողը զբաղված է");
                return;
            }

            if (!myHasBusy.status) {
                AlertComponent(
                    ALERT_MESSAGE_TYPE_DANGER,
                    "Դուք արդեն զանգի մեջ եք"
                );
                return;
            }

            await videoRoomStore.setPersonalRoom({
                answer_username: userStore.username,
            });
            await videoRoomStore.getVideoRoomByName();

            setWaitAnswerCall(true);
            setIsOpen(true);
            socket.emit(SOCKET.MAKE_PRIVATE_CALL, {
                name: username,
                caller: userStore.username,
                room_id: videoRoomStore.roomId,
            });
        };

        return (
            <Menu as="div">
                <Menu.Button
                    onClick={() => setSelectedUser(user.username)}
                    className="flex items-center text-sm py-5 pl-5 h-auto text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                >
                    <div className="shrink-0 relative">
                        <img
                            src={
                                user.image
                                    ? `${BACKEND_URL}${user.image}`
                                    : userAvatar
                            }
                            className="rounded-full w-10 h-10"
                            alt="Avatar"
                        />
                        <span className="flex absolute top-0 right-0">
                            {status && (
                                <>
                                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-600 text-white opacity-75" />
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-600 text-white" />
                                </>
                            )}
                        </span>
                        <span className="flex absolute bottom-0.5 right-0">
                            {usersStore.unviewedChats.includes(
                                user.username
                            ) && (
                                <>
                                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75" />
                                    <span className="relative bg-red-400 h-3 w-3 rounded-full" />
                                </>
                            )}
                        </span>
                    </div>
                    <div className="grow w-full">
                        <div className="relative inline-block text-left">
                            <div className="flex  py-2 space-x-5 text-center">
                                <div className="w-40 overflow-hidden whitespace-pre-wrap">
                                    {user.name || user.last_name
                                        ? user.name +
                                          " " +
                                          user.last_name +
                                          " (" +
                                          user.username +
                                          ")"
                                        : user.username}
                                </div>
                                <div className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100;">
                                    <FontAwesomeIcon
                                        icon={faCogs}
                                        className="text-black"
                                    />
                                </div>
                            </div>
                            <Menu.Items className="absolute z-10 w-40 rounded-md shadow-lg bg-white  focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() =>
                                                    videoCallHandleClick(
                                                        user.username
                                                    )
                                                }
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                                style={style}
                                            >
                                                Զանգ
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <hr />
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                                style={style}
                                                onClick={() =>
                                                    chatHandleClick()
                                                }
                                            >
                                                Նամակ
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <hr />
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                                style={style}
                                                onClick={() =>
                                                    chatNewTabHandleClick()
                                                }
                                            >
                                                Նամակ նոր էջում
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </div>
                    </div>
                </Menu.Button>
            </Menu>
        );
    }
);
export default memo(UserElement);
