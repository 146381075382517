import React, { memo } from "react";
import "./Toggle.css";

const Toggle = ({ defaultChecked, onHandelChange }) => {
    return (
        <div className=" ">
            <h4>Ստեղծել հանդիպում</h4>
            <label className="switch ">
                <input
                    type="checkbox"
                    id="create_meeting"
                    name="create_meeting"
                    onChange={(e) => onHandelChange(e)}
                    className="appearance-none checked:bg-blue-500"
                    checked={defaultChecked}
                />
                <span className="slider  round"></span>
            </label>
        </div>
    );
};

export default Toggle;
