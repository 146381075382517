import React, {useEffect} from "react";
import SideNav from "./SideNav/SideNav";
import Content from "./Content";
import {NotificationContainer} from "react-notifications";
import {socket} from "../../const/socket";
import Socket from "../../const/socketNames"
import {useStore} from "../../store";

const Dashboard = () => {
    const {userStore} = useStore()
    useEffect(() => {
        socket.emit(Socket.JOIN_CONNECTED,userStore.username)
    })
     return (
        <div className="App flex">
             <div className="flex h-full">
               <SideNav/>
             </div>
            <Content/>
            <NotificationContainer />
        </div>
     )
}

export default Dashboard