import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({
    setField,
    setSize,
    uploadFile,
    purpose,
    classNames,
    setLoading,
    setBigFile,
    idName,
}) => {
    const [wait, setWait] = useState(false);

    useEffect(() => setLoading(wait), [wait]);

    const handleChange = async (e) => {
        const data = new FormData();
        data.append("file", e.target.files[0]);
        data.append("filename", e.target.files[0]?.name);
        purpose && data.append("purpose", "meeting");
        if (e.target.files[0].size < 5 * 1000 * 1000 * 1000) {
            setWait(true);
            const sendFile = await uploadFile(data);
            if (sendFile) {
                const { path } = sendFile;
                setWait(false);
                setField(path);
            } else {
                setWait(false);
            }
            if (setSize) {
                setSize(sendFile?.size);
            }
        } else {
            setBigFile(true);
            setTimeout(() => {
                setBigFile(false);
            }, 5000);
        }
    };

    return (
        <div className="mx-2 mt-2 img-upload">
            <label
                htmlFor={idName ? idName : "file-input"}
                style={{ cursor: "pointer" }}
            >
                <FontAwesomeIcon
                    icon={faFileUpload}
                    className={"fa-lg" + classNames}
                />
            </label>
            <input
                className="hidden"
                id={idName ? idName : "file-input"}
                type="file"
                name="file"
                onChange={(e) => handleChange(e)}
            />
        </div>
    );
};

export default FileUpload;
