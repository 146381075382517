import React, {useState} from 'react';
import {useStore} from "../../../store";
import AlertComponent from "../../../utils/Alert";
import {ALERT_MESSAGE_TYPE_DANGER} from "../../../const/Constants";
import PinCodeList from "./PinCodeList";

const GeneratePin = () => {
    const [pin, setPin] = useState('');
    const [count, setCount] = useState('');
    const [usedUsername, setUsedUsername] = useState("")
    const {adminStore} = useStore()


    const random = () => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 8; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        setPin(text)
    }

    const sendPinCode = () => {
        if (!pin)
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, "Անհրաժեշտ է ընտրել փին գաղտնաբառ")
        adminStore.createPinCode(pin, count, usedUsername).then(() => {
            setCount("")
            setUsedUsername("")
            setPin("")
        })

    }

    return (
        <div className='bg-white basis-1/2 p-2'>
            <h2> Գեներացնել նոր Pin Code </h2>
            <div className="relative z-0 w-full group m-2 mb-4">
                <input type='text' name="code" id="code"
                       className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={pin} onChange={(e) => setPin(e.target.value)}/>
                <label htmlFor="code"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Պին
                    Կոդ</label>
            </div>
            <div className="relative z-0 w-full group m-2">
                <input type='number' name="used_limit" id="used_limit"
                       className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={count} onChange={(e) => setCount(e.target.value)}/>
                <label htmlFor="used_limit"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Օգտագործման
                    Քանակ</label>
            </div>
            <div className="relative z-0 w-full group m-2">
                <input type='text' name="used_username" id="used_username"
                       className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={usedUsername} onChange={(e) => setUsedUsername(e.target.value)}/>
                <label htmlFor="used_username"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Ում է տրվելու
                    </label>
            </div>

            <button onClick={() => random()}>Գեներացնել</button>
            <button className="bg-blue-600 text-white rounded p-2 m-2" onClick={sendPinCode}>Հաստատել</button>
            <PinCodeList/>
        </div>
    );
};

export default GeneratePin;