import React, { useEffect, useState } from "react";
import {
    ALERT_MESSAGE_TYPE_DANGER,
    ALERT_MESSAGE_TYPE_SUCCESS,
} from "../const/Constants";
import { useStore } from "../store";
import AlertComponent from "../utils/Alert";
import { passwordValidation } from "../utils/validate";

const ChangePassword = () => {
    const [data, setData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: "",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [runCheck, setRunCheck] = useState(true);
    const { userStore } = useStore();

    const handleChange = (e) => {
        setRunCheck(!runCheck);
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrorMessage("");

        if (name === "confirm_password") {
            data.new_password !== value &&
                setErrorMessage("Գաղտնաբառը չի համընկնում");
        }

        if (name === "new_password") {
            const result = passwordValidation(value);
            !result.isValid && setErrorMessage(result.message);
        }
    };

    useEffect(() => {
        let { new_password, confirm_password } = data;
        if (new_password !== confirm_password) {
            setDisabled(true);
            if (new_password && confirm_password)
                setErrorMessage("Գաղտնաբառը չի համընկնում");
        } else if (new_password.length < 8) {
            setDisabled(true);
            if (new_password.length) {
                setErrorMessage("Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ");
            }
        } else {
            setDisabled(false);
        }
    }, [runCheck]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { old_password, new_password } = data;
        if (new_password.length < 8) {
            setErrorMessage("Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ");
            return;
        }
        const isChanged = await userStore.changeCurrentPassword(
            old_password,
            new_password
        );
        const status = isChanged.status
            ? ALERT_MESSAGE_TYPE_SUCCESS
            : ALERT_MESSAGE_TYPE_DANGER;
        setData({
            old_password: "",
            new_password: "",
            confirm_password: "",
        });
        AlertComponent(status, isChanged.message);
    };

    const { old_password, new_password, confirm_password } = data;

    return (
        <form onSubmit={handleSubmit} className="flex flex-col p-3">
            <div className="relative z-0 mb-6 w-full group">
                <input
                    type="password"
                    name="old_password"
                    id="old_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={old_password}
                    onChange={(e) => handleChange(e)}
                    required
                />
                <label
                    htmlFor="old_password"
                    className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Հին Գաղտնաբառ{" "}
                </label>
            </div>

            <div className="relative z-0 mb-6 w-full group">
                <input
                    type="password"
                    name="new_password"
                    id="new_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={new_password}
                    onChange={(e) => handleChange(e)}
                    required
                />
                <label
                    htmlFor="new_password"
                    className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Նոր Գաղտնաբառ
                </label>
            </div>

            <div className="relative z-0 mb-6 w-full group">
                <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={confirm_password}
                    onChange={(e) => handleChange(e)}
                    required
                />
                <label
                    htmlFor="confirm_password"
                    className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Հաստատել Գաղտնաբառը
                </label>
            </div>

            {errorMessage === "" ? null : (
                <span className="text-red-500 text-sm text-center">
                    {errorMessage}
                </span>
            )}

            <button
                type="submit"
                disabled={disabled}
                className={`${
                    disabled ? "bg-gray-600" : "bg-blue-600"
                } text-white rounded`}
            >
                Հաստատել
            </button>
        </form>
    );
};

export default ChangePassword;
