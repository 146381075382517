import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import {
    ALERT_MESSAGE_TYPE_SUCCESS,
    BACKEND_URL,
    MESSAGE_MY,
} from "../const/Constants";
import { SocketContext } from "../const/socket";
import avatarImage from "../assets/images/user_avatar.png";
import { useStore } from "../store";
import FileUpload from "./FileUpload";
import MessageItem from "./MessageItem";
import SOCKET from "../const/socketNames";
import DeleteMessageRoom from "./DeleteMessageRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCheckCircle,
    faPaperPlane,
    faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import UserChat from "../states/UserChat";
import AlertComponent from "../utils/Alert";
import { Menu } from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const SingleChat = observer(({ selected }) => {
    const { userStore, chatStore, usersStore } = useStore();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [bigFile, setBigFile] = useState(false);
    const [offset, setOffset] = useState(0);
    const [allUsers, setAllUsers] = useState({});
    const [size, setSize] = useState(0);
    const ref = useRef(null);
    const socket = useContext(SocketContext);

    function scrollDown() {
        document.getElementById("messages").scrollTop =
            document.getElementById("messages").scrollHeight;
    }

    async function moreData() {
        if (chatStore.username !== "ALL") {
            await chatStore.getMessages({
                roomId: chatStore.getRoomId,
                offset: offset + 10,
            });
            setOffset((offset) => offset + 10);
        } else if (chatStore.user === "ALL") {
            setOffset((offset) => offset + 10);
            await chatStore.getAllMessages(offset, 10);
        }
    }

    const sendMessage = () => {
        if (!message) {
            return;
        }

        socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
            message: message.replace(/\r?\n/g, "\n").trim(),
            room_id: chatStore.getRoomId,
            role: userStore.role,
            sender_username: userStore.username,
            receiver_username: chatStore.username,
            size,
        });
        setMessage("");
    };

    useEffect(() => {
        setBigFile(false);
    }, [selected]);

    const chatCloseHandleClick = () => {
        socket.emit(SOCKET.CHAT_CLOSE, {
            username: userStore.username,
            guestUsername: chatStore.username,
        });
        chatStore.clearUsername();
        chatStore.clearMessages();
    };

    const chatHandleClick = (guestUsername) => {
        setOffset(10);
        chatStore.clearUsername();
        chatStore.clearMessages();
        if (guestUsername === "Admin") {
            socket.emit(SOCKET.CHAT_CREATE, {
                username: userStore.username,
                guestUsername: "Admin",
            });
        } else {
            socket.emit(SOCKET.CHAT_CREATE, {
                username: userStore.username,
                guestUsername: guestUsername,
            });
        }
        chatStore.setUsername(guestUsername);
    };

    useEffect(() => {
        socket.on(SOCKET.CHAT_ROOM_INFO, (room) => {
            chatStore.setRoomId(room.id);
            socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, { roomId: room.id });
            chatStore.getMessages({ roomId: chatStore.getRoomId, offset: 0 });
        });
        socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
            chatStore.addMessage(
                message.message,
                MESSAGE_MY,
                new Date().toLocaleDateString("en", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "numeric",
                    hour12: false,
                    minute: "numeric",
                }),
                null,
                message._id
            );
            setTimeout(function () {
                scrollDown();
            }, 0);
        });

        return () => {
            socket.off(SOCKET.GET_MY_MESSAGE_MYSELF);
            socket.off(SOCKET.CHAT_ROOM_INFO);
            socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM);
        };
    }, []);

    useEffect(() => {
        socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {
            if (chatStore.username === data.username)
                chatStore.addMessage(
                    data.message,
                    data.type,
                    new Date().toLocaleDateString("en", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "numeric",
                        hour12: false,
                        minute: "numeric",
                    }),
                    null,
                    data.id
                );
            if (data.role === "Admin") {
                chatStore.setAdminChat(true);
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                    name: userStore.username,
                    caller: data.username,
                });
            } else {
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                    name: userStore.username,
                    caller: data.username,
                });
            }

            setTimeout(function () {
                scrollDown();
            }, 0);
        });
        socket.on(SOCKET.UPDATE_MESSAGES, () => {
            if (chatStore.user === "ALL") {
                chatStore.getAllMessages(0, chatStore.messageHistory.length);
            } else {
                chatStore.getMessages({
                    roomId: chatStore.getRoomId,
                    offset: 0,
                    limit: chatStore.messageHistory.length,
                });
            }
        });
        return () => {
            socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE);
            socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE);
        };
    }, [socket]);

    useEffect(() => {
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            if (chatStore.user === data.guest_username) {
                chatStore.setIsRead();
            }
            if (chatStore.user === "ALL") {
                chatStore.getAllMessages(0, chatStore.messageHistory.length);
            } else {
                chatStore.getMessages({
                    roomId: chatStore.getRoomId,
                    offset: 0,
                    limit: chatStore.messageHistory.length,
                });
            }
        });
        return () => {
            socket.off(SOCKET.CHAT_REFRESH_ROOMS);
        };
    }, []);

    useEffect(() => {
        if (chatStore.username === "ALL") {
            chatStore.getAllMessages(0, 10);
            setOffset(10);
            usersStore.users.forEach((el) =>
                setAllUsers((prev) => ({
                    ...prev,
                    [el.username]:
                        el.name || el.last_name
                            ? `${el.name} ${el.last_name}`
                            : el.username,
                }))
            );
            usersStore.unviewedChats = [];
        }
        if (chatStore.username === "Admin") {
            chatStore.getAdminMessages();
        }
        setTimeout(function () {
            scrollDown();
        }, 100);
    }, [chatStore.username]);

    const deleteChatRoom = async () => {
        if (window.confirm("Ջնջել Նամակները")) {
            const result = await UserChat.deleteMessageRoomById();
            socket.emit(SOCKET.DELETE_ALL_MESSAGES, chatStore.user);
            if (result.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message);
                chatCloseHandleClick();
            }
        }
    };

    const deleteChatMessage = async (message) => {
        if (window.confirm("Ջնջել Նամակը")) {
            chatStore.deleteMessageById(message).then((res) => {
                socket.emit(SOCKET.DELETE_MESSAGE, chatStore.user);
                if (chatStore.user === "ALL") {
                    chatStore.getAllMessages(
                        0,
                        chatStore.messageHistory.length
                    );
                } else {
                    chatStore.getMessages({
                        roomId: chatStore.getRoomId,
                        offset: 0,
                        limit: chatStore.messageHistory.length,
                    });
                }
            });
        }
    };

    return (
        <div
            ref={ref}
            className="absolute border-2 bottom-10 right-10 bg-white flex-1 p:2 sm:p-3 justify-between flex flex-col h-3/5 w-1/3 z-40"
        >
            <div className="flex sm:items-center pb-2 justify-between  border-b-2 border-gray-200">
                <div className="relative flex items-center space-x-4">
                    <div className="relative">
                        {chatStore.user !== "ALL" && (
                            <img
                                src={
                                    usersStore.users.find(
                                        (el) => el.username === chatStore.user
                                    )?.image
                                        ? `${BACKEND_URL}${
                                              usersStore.users.find(
                                                  (el) =>
                                                      el.username ===
                                                      chatStore.user
                                              ).image
                                          }`
                                        : avatarImage
                                }
                                alt="avatar"
                                className="w-10 sm:w-16 h-10 sm:h-16 rounded-full"
                            />
                        )}
                    </div>
                    <div className="flex flex-col leading-tight">
                        <div className="text-2xl mt-1 flex items-center">
                            <span className="text-gray-700 mr-3">
                                {usersStore.users.find(
                                    (el) => el.username === chatStore.user
                                )?.name ||
                                usersStore.users.find(
                                    (el) => el.username === chatStore.user
                                )?.last_name ||
                                usersStore.users.find(
                                    (el) => el.username === chatStore.user
                                )?.father_name
                                    ? usersStore.users.find(
                                          (el) => el.username === chatStore.user
                                      )?.name +
                                      " " +
                                      usersStore.users.find(
                                          (el) => el.username === chatStore.user
                                      )?.last_name +
                                      " " +
                                      usersStore.users.find(
                                          (el) => el.username === chatStore.user
                                      )?.father_name
                                    : chatStore.user === "ALL"
                                    ? "Իմ Նամակները"
                                    : chatStore.user}
                            </span>
                        </div>
                        <span className="text-lg text-gray-600" />
                    </div>
                </div>
                <div className="flex">
                    <div className="flex items-center space-x-2 mr-3">
                        {chatStore.user !== "ALL" && (
                            <button
                                type="button"
                                onClick={() => deleteChatRoom()}
                                className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-red-200 focus:outline-none"
                            >
                                <DeleteMessageRoom />
                            </button>
                        )}
                    </div>

                    <div className="flex items-center space-x-2">
                        <button
                            type="button"
                            onClick={() => chatCloseHandleClick()}
                            className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                            <FontAwesomeIcon
                                icon={faWindowClose}
                                className="fa fa-window-close"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div
                id="messages"
                className="flex flex-col space-y-4 p-3 h-full overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
            >
                {chatStore.messages.length >= 10 &&
                chatStore.messages.length >= offset ? (
                    <div>
                        <button onClick={() => moreData()}>
                            Տեսնել ավելին
                        </button>
                    </div>
                ) : (
                    ""
                )}
                {chatStore.messages.map((message, index) => {
                    if (message.type === MESSAGE_MY) {
                        return (
                            <div className="chat-message" key={index}>
                                <div className="flex items-end justify-end">
                                    <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                                        {chatStore.user === "ALL" && (
                                            <div
                                                className="hover:cursor-pointer"
                                                onClick={() =>
                                                    chatHandleClick(
                                                        message.receiver_username
                                                    )
                                                }
                                            >
                                                {
                                                    allUsers[
                                                        message
                                                            .receiver_username
                                                    ]
                                                }
                                            </div>
                                        )}
                                        <div className="flex flex-col">
                                            <Menu
                                                as="div"
                                                className="relative inline-block text-left"
                                            >
                                                <div className="flex  py-2 space-x-5 text-center">
                                                    <Menu.Button>
                                                        <span
                                                            className="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white text-start min-w-15 max-w-md"
                                                            style={{
                                                                wordBreak:
                                                                    "break-all",
                                                            }}
                                                        >
                                                            <MessageItem
                                                                message={
                                                                    message.message
                                                                }
                                                            />
                                                        </span>
                                                    </Menu.Button>
                                                </div>
                                                <Menu.Items className="absolute z-10 w-40 rounded-md shadow-lg bg-white  focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() =>
                                                                        deleteChatMessage(
                                                                            message
                                                                        )
                                                                    }
                                                                    className={classNames(
                                                                        active
                                                                            ? "bg-gray-100 text-gray-900"
                                                                            : "text-gray-700",
                                                                        "block px-4 py-2 text-sm"
                                                                    )}
                                                                >
                                                                    Ջնջել
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Menu>
                                        </div>
                                        <span className=" text-xxs text-gray-500 text-end px-2">
                                            {message.created_at}{" "}
                                            {chatStore.user !== "ALL" &&
                                                (message.is_viewed ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        className="fa fa-check-circle text-green-400"
                                                        aria-hidden="true"
                                                    ></FontAwesomeIcon>
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="fa fa-check"
                                                        aria-hidden="true"
                                                    ></FontAwesomeIcon>
                                                ))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="chat-message" key={index}>
                                <div className="flex items-end">
                                    <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                        {chatStore.user === "ALL" && (
                                            <div
                                                className="hover:cursor-pointer"
                                                onClick={() =>
                                                    chatHandleClick(
                                                        message.username
                                                    )
                                                }
                                            >
                                                {allUsers[message.username]}
                                            </div>
                                        )}
                                        <div className="flex flex-col">
                                            <Menu
                                                as="div"
                                                className="relative inline-block text-left"
                                            >
                                                <div className="flex  py-2 space-x-5 text-center">
                                                    <Menu.Button>
                                                        <span
                                                            className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600 min-w-15 text-start max-w-md"
                                                            style={{
                                                                wordBreak:
                                                                    "break-all",
                                                            }}
                                                        >
                                                            <MessageItem
                                                                message={
                                                                    message.message
                                                                }
                                                            />
                                                        </span>
                                                    </Menu.Button>
                                                </div>
                                                <Menu.Items className="absolute z-10 w-40 rounded-md shadow-lg bg-white  focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() =>
                                                                        deleteChatMessage(
                                                                            message
                                                                        )
                                                                    }
                                                                    className={classNames(
                                                                        active
                                                                            ? "bg-gray-100 text-gray-900"
                                                                            : "text-gray-700",
                                                                        "block px-4 py-2 text-sm"
                                                                    )}
                                                                >
                                                                    Ջնջել
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Menu>

                                            <span className="block text-xxs text-gray-500 text-end p-2">
                                                {message.created_at}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
            {chatStore.user !== "ALL" && (
                <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                    <div className="relative flex">
                        <div className="basis-8/12">
                            <div className="absolute left-2 top-1">
                                <FileUpload
                                    setSize={setSize}
                                    setField={setMessage}
                                    setLoading={setLoading}
                                    uploadFile={chatStore.uploadMessages}
                                    setBigFile={setBigFile}
                                    idName="file-input-private"
                                />
                            </div>
                            <textarea
                                rows={
                                    Math.ceil(message.length / 25) < 4 ? 2 : 4
                                }
                                cols="100"
                                value={message}
                                onKeyDown={(event) =>
                                    event.key === "Enter" &&
                                    (event.ctrlKey ||
                                        event.metaKey ||
                                        event.shiftKey)
                                        ? (() => {
                                              event.preventDefault();
                                              sendMessage();
                                          })()
                                        : ""
                                }
                                onChange={(event) =>{message.length < 4000 ? setMessage(event.target.value) : ""}
                                    
                                }
                                placeholder={
                                    bigFile
                                        ? "Ֆայլը չպետք է գերազանցի 5GB-ը"
                                        : loading
                                        ? "Բեռնվում է..."
                                        : "Հաղորդագրություն"
                                }
                                className="w-full resize-none focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"
                            />
                        </div>
                        <div className="absolute basis-auto right-0 items-center inset-y-0 hidden sm:flex">
                            <button
                                disabled={loading}
                                type="button"
                                onClick={sendMessage}
                                className="inline-flex items-center justify-center rounded-lg px-4 py-3 bg-blue-600 text-white hover:bg-blue-400 focus:outline-none"
                            >
                                <span className="font-bold mr-1">Ուղարկել</span>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default SingleChat;
